.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}

.title {
  font-size: 1rem;
  font-weight: bold;
}

.group {
  display: flex;
  gap: 10px;
  margin: auto;
}

.box {
  position: relative;
}

.group svg {
  width: 70px;
  height: 70px;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  cursor: pointer;
}

.group .tooltip {
  opacity: 0;
  visibility: none;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: white;
  font-size: 0.6rem;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.group .box:hover .tooltip {
  opacity: 1;
}

.group svg.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.buttons button {
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
}

.buttons button:first-child {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
