@keyframes slideInFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
.overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); 
    backdrop-filter: blur(5px); 
  
  }

  .resetpasswordmodal {
    top: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1001;
    animation: slideInFromRight 0.5s ease forwards; 
  } 

html, body {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}



.loginContainer form {
    display: flex;
    flex-direction: column;
}
.loginContainer label {
    margin-top: 10px;
    margin-left: 10px;
    color: gray;
    font-size: 14px;
}
.loginContainer input {
    padding: 10px;
    width: 100%;
    margin-top: 5px;
    box-sizing: border-box;
}
.loginContainer button {
   
    padding: 10px;
    margin-top: 20px;
    box-sizing: border-box; /* Assure que le padding est inclus dans la largeur */
    margin-bottom: 4%;
}

.Close {
    font-size: larger;
    font-weight: bold;
    padding: 10px 10px;
    color: rgb(109, 109, 109);
    cursor: pointer;
    display: flex;
    align-items: flex-end;
}
.Title {
    font-size: 15px;
    color: rgb(109, 109, 109);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
}

.Title p {
    font-size: 24px;
    color: #2c2c2c;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-weight: 600;
}
.text{
    color: gray;
    align-self: flex-start;
    margin-bottom: 7px;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    outline: none;
    /* border: none; */
    width: 370px;
    padding: 15px;
    border-radius: 9px;
    /* border-radius: 14px; */
 
    font-size: 14px;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
}

.options {
    display: flex;
    width: 100%;
    max-width: 570px;
    height: 20px;
    margin-top: 0px;
    box-sizing: border-box;
    align-items: center;
    margin-bottom: 1%;
    
}


.checkBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    flex-direction: row;
 
}

.checkBox label {
            margin: 0 5px 0 0;
            display: inline-block;
            flex-direction: column;
            text-align: center;
            margin-left: 5px;
            text-align: left; 
}

.options a {
    color: #407BFF;
    cursor: pointer;
}

.error_msg {
    width: 370px;
    padding: 8px;
    margin: 5px 0;
    font-size: 14px;
    background-color: #f34646;
    color: white;
    border-radius: 5px;
    text-align: center;
}

.Login_btn {
    width: 100%;
    height: 50px;
    border: 2px solid #f3f3f3;
    margin-top: 20px;
    background-color: #C3C3C3;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s; /* Ajoute une transition pour le changement de couleur */
  }
  
  .btnActive {
   background-color: #3E4678;
    
  }
  .signupLink {
    text-decoration: underline;
    color: #CD6214;
    font-size: 16px;
    font-weight: bold;
    margin:0px
  }
  

.signup {
    display: flex;
    justify-content: end;
    align-items: center;
    color: #263238;
    font-size: 15px;
    margin: 3px 0;
    padding-left: 7px;
}
.images{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 200px;
    margin-top: 20px;
    justify-content: space-between;
    gap: 10px;
}

.loginImageContainer{
 
   
    width: 40%;
    max-width: 600px;
    padding: 20px;
}
.logo{
    margin-top: 0;
   
}
.by{
    color: black;
    font-size: 12px;
    align-self: start;
}
.by u{
    color: black;
    font-size: 12px;
    align-self: start;
}
.nvaccount{
    color:  #666666;
    border: 1.8px solid #0000001d;
    border-radius: 12px;
    width: 310px;
    height: 46px;
    display: flex;              
    justify-content: center;    
    align-items: center; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}
.nvaccount u{
    color: black;
    font-size: 13px;
}
.forgetpsw u{
    color: black;
    font-size: 13px;
    /* align-self: start; */
    /* margin-top: 10px; */
    font-weight: 700;
}
.dividerImage{
    width: 30%;
    margin-top: 20px;
    height: 100%;
    
}
    
.divider{
    display: flex;
    justify-content: space-between;
    margin-block: 1%;

}
.or{
    color: black;
    font-size: 13px;
    
    font-weight: 700;
}
.allsocialmedia{
    display: flex;
    align-items: center;
    justify-content: center;
}
.socialmedia{
    padding: 7%;
}
.socialmediaF{
    padding-inline: 2%;
    margin-top: 3%;
    padding-bottom: 9%;
}
.Container{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    height: 100vh;
    background-color: #3E4678;
    justify-content: space-between;
}
.backLogin {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #345AA4 20%, #3E4678 100%);

}

.container {
    display: flex;
    width: 90%;
    
    justify-content: space-between;
    align-items: center;
}

.leftSection, .rightSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
 
}

.leftSection {
    
}

.rightSection {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
   
    justify-content: space-evenly;
    align-items: center;
}
@media (max-width: 570px) {
    .rightSection {
      display: none;
    }
  }
  

.logo {
    margin-top: 0;
    margin-bottom: 20px;
    width: 22%;
}


.loginContainer {
    width: 100%;
    max-width: 556px;
    padding: 6% 7% 6% 6%;
    background-color: #fff;
    box-shadow: 0 1px 23px 2px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
}
.hidecontainer{

}
.hide{
    /* display: flex;
    align-content: center;
    justify-content: flex-end; */
}

.loginImage {
    max-width: 110%;
    height: auto;
}

.textcontainer {
    text-align: center;
    margin-top: 20px;
}

.textsearchtitle {
    font-size: 2.5rem;
    font-weight: 700;
    color: white;
    font-family: lato;
    margin-top: -4%;
}

/* For small screens (phones) */
@media (min-width: 570px) and (max-width: 640px) {
    .textsearchtitle {
        font-size: 18px; /* Adjust font size for small screens */
       
    }
}
@media (min-width: 640px) and (max-width: 750px) {
    .textsearchtitle {
        font-size: 20px; /* Adjust font size for tablets */
      
    }
}

/* For medium screens (tablets) */
@media (min-width: 750px) and (max-width: 850px) {
    .textsearchtitle {
        font-size: 24px; /* Adjust font size for tablets */
      
    }
}
@media (min-width: 850px) and (max-width: 950px) {
    .textsearchtitle {
        font-size: 27px; /* Adjust font size for small desktops */
        
    }
}

/* For large screens (small desktops) */
@media (min-width: 950px) and (max-width: 1024px) {
    .textsearchtitle {
        font-size: 29px; /* Adjust font size for small desktops */
        
    }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) and (max-width: 1240px) {
    .textsearchtitle {
        font-size: 32px; /* Keep the original font size for large desktops */
        
    }
}
@media (min-width: 1240px) and (max-width: 1300px) {
    .textsearchtitle {
        font-size: 40px; /* Keep the original font size for large desktops */
     
    }

}

.textsearch {
    font-size: 15px;
    color: white;
    margin-top: 4%;
}
/* For small screens (phones) */
@media (min-width: 570px) and (max-width: 640px) {
    .textsearch {
        font-size: 6px; /* Adjust font size for small screens */
       
    }
}
@media (min-width: 640px) and (max-width: 750px) {
    .textsearch {
        font-size: 7px; /* Adjust font size for tablets */
      
    }
}

/* For medium screens (tablets) */
@media (min-width: 750px) and (max-width: 850px) {
   .textsearch {
        font-size: 8px; /* Adjust font size for tablets */
      
    }
}
@media (min-width: 850px) and (max-width: 950px) {
   .textsearch {
        font-size: 9px; /* Adjust font size for small desktops */
        
    }
}

/* For large screens (small desktops) */
@media (min-width: 950px) and (max-width: 1024px) {
   .textsearch {
        font-size: 10px; /* Adjust font size for small desktops */
        
    }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) and (max-width: 1240px) {
   .textsearch {
        font-size: 11px; /* Keep the original font size for large desktops */
        
    }
}
@media (min-width: 1240px) and (max-width: 1300px) {
   .textsearch {
        font-size: 13px; /* Keep the original font size for large desktops */
     
    }

}

.textsearch2 {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    /* align-content: center; */
    justify-content: center;
    margin-top: 4%;
}
/* For small screens (phones) */
@media (min-width: 570px) and (max-width: 640px) {
   .textsearch2 {
        font-size: 6px; /* Adjust font size for small screens */
       
    }
}
@media (min-width: 640px) and (max-width: 750px) {
   .textsearch2 {
        font-size: 7px; /* Adjust font size for tablets */
      
    }
}

/* For medium screens (tablets) */
@media (min-width: 750px) and (max-width: 850px) {
  .textsearch2 {
        font-size: 8px; /* Adjust font size for tablets */
      
    }
}
@media (min-width: 850px) and (max-width: 950px) {
  .textsearch2 {
        font-size: 9px; /* Adjust font size for small desktops */
        
    }
}

/* For large screens (small desktops) */
@media (min-width: 950px) and (max-width: 1024px) {
  .textsearch2 {
        font-size: 10px; /* Adjust font size for small desktops */
        
    }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) and (max-width: 1240px) {
  .textsearch2 {
        font-size: 11px; /* Keep the original font size for large desktops */
        
    }
}
@media (min-width: 1240px) and (max-width: 1300px) {
  .textsearch2 {
        font-size: 13px; /* Keep the original font size for large desktops */
     
    }

}
@media (min-width:570px) and (max-width:840px){
    .text {
        margin-top: 10px;
        font-size: 12px;
    }
    .by {
        font-size: 8px;
        
    }
    .by u {
        font-size: 8px;
        
    }
    .loginContainer label {
        font-size: 11px;

}
.forgetpsw u {
    color: black;
    font-size: 10px;
    /* align-self: start; */
    /* margin-top: 10px; */
    font-weight: 700;
}
}
@media (max-width:420px){
    .text {
        margin-top: 10px;
        font-size: 12px;
    }
    .by {
        font-size: 8px;
        
    }
    .by u {
        font-size: 8px;
        
    }
    .loginContainer label {
        font-size: 11px;

}
.forgetpsw u {
    color: black;
    font-size: 10px;
    /* align-self: start; */
    /* margin-top: 10px; */
    font-weight: 700;
}
}