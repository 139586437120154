.errorHandling{
  color: red;
   margin-top: -10px 
}
.successMessage {
  margin-top: 20px;
  margin-left: 33px;
 
}
.errorMessage{
  margin-top: 20px;
  margin-left: 33px;
 
}
.container {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-left: 50%;
    height: 100%;
    width: 50%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .box {
    display:flex
  }
  
  .header {
    margin-left: 126px;
    margin-top: 30px;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .inputGroup {
    margin-bottom: 15px;
  }
  
  .input {
    background-color: #F2F2F2;
  font-family: "lato",sans-serif;
    padding: 10px;
    border-radius: 10px;
    border: 1px #ccc;
    font-size: 15px;
    margin: 20px 0 20px;
    width: 100%; 
  

  }

  .button{
    width: 100%;
    padding: 10px;
    color: #fff;
    background-color: #CD6214;
    border-radius: 10px;
    cursor: pointer;
}
.label {
    font-weight: bold;
}
  
.img {
  height: 70px;
  width: 70px;
  margin-left: 142px;
  margin-top: 10px;
}
.underline {
    margin-top: 1px;
    border-top: 3px solid #cd6214;
    width: 80px;
    border-radius: 4px;
    margin-bottom: 25px;
    margin-left: 127px;

  }
  