@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
    margin: 0 5px;
}


.headerContainer{
    width:100%;
    height: 280px;
    border-bottom-left-radius:40px ;
    border-bottom-right-radius:40px ;
    background: radial-gradient(#345AA4 10%,#3E4678 60%, #3E4678 100% );
 
}

.titleContainer{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    width:fit-content;
    height: 55%;
    margin-inline: auto;

}
 
.title{
    font-size: 40px; 
    color:white;
    text-transform: capitalize;
}

.underline{
    width:100px;
    height: 5px;
    background-color: #CD6214;
    border-radius:20px;

}

@media  (max-width:770px ) {
    .title{
        font-size: 30px; 
         
    }
    
    .underline{
        width:80px;
         
    
    }
}