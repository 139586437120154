 .coursesContainer{
    width : 70%;
    max-width: 1000px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center; 

 }
 .coursesContainerInner{
    width: 80%;
    height: auto; /* change it to 25 rem for scroll */ 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap:30px; 
    margin:auto;

 }
.titleContainer h1{
    font-size: 36px;
    text-transform: capitalize;
}
 .underline{
    width:100px;
    height: 5px;
    background-color: #CD6214;
    border-radius:20px;
}

.courses{
    width: 100%;  
    height:80%;     
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    margin-inline: 20px;
    border-radius: 40px;
    box-shadow: 0px 0px 4px 0px  rgba(0, 0, 0, 0.28);
    padding-block: 20px;

}

.coursesInner{
    width: 100%;
    height:80%;    
    /* overflow-y: scroll; */
    padding-left: 20px;
    padding-right: 20px; 
}

@media  (max-width:1330px) {
 
    .coursesInner{
 
        width: 80%;
    }
}

@media  (max-width:1200px) {
    .coursesContainer{
        width : 100%; 
     }
     .coursesContainerInner{
        width: 80%;  
        justify-content: center;
        align-items: center;
     }
     .titleContainer {
        width:80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    .titleContainer h1{
        font-size: 30px;
    }
     .underline{
        width:70px;
        height: 4px; 
    }
    .coursesInner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
 
}