.ShowVouchers {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .Cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .CardVoucher {
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 250px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .CardBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .Config {
    display: flex;
    justify-content: space-between;
  }
  
  .icons {
    cursor: pointer;
    color: #555;
  }
  
  .icons:hover {
    color: #000;
  }
  
  .Details {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  