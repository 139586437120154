.navigation {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.nav_button {
  background: none;
  border: none;
  cursor: pointer;
}

.nav_date {
  font-weight: bold;
  font-size: 1rem;
}

.nav_icon {
  width: 0.9rem;
  height: 0.9rem;
}
