.featuredContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  
}

.featuredTitle {
  margin-bottom: 2rem;
  margin-left: 4rem;
  padding-bottom: 50px;
}

.underline {
  width: 10%;
  background-color: #cd6214;
  padding-top: 3px;
  border-radius: 100px;
  margin-top: 7px;
}

.cardsWrapper {
  display: flex;
  align-items: center;
  align-self: center;
  
}

.navButton {
  color: #cd6214;
  border: 0px dotted #dddddd00;
  background-color: #3e467800;
  width: 50%;
  cursor: pointer;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.navButton:hover {
  color: #0056b3;
}

.cardsContainer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 2rem;
}


.card {
  background-color: #3e4678;
  color: #d9d9d9;
  width: 16.5rem;
  height: 15rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid black;
  position: relative;
  overflow: visible;
}

.imageWrapper {
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid white;
  margin-bottom: 1rem;
  position: absolute;
  top: -4rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
}


.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.uppercase {
  text-transform: uppercase;
  
}

.titleName {
  font-size: 1.6rem;
  text-align: center;
  margin: 0px 0px 15px 0px;
}
.titleName::first-letter {
  text-transform: uppercase;
}


.description {
  font-size: 1rem;
  text-align: center;
  white-space: normal;
  padding: 0 12% 0 12%;
 
}
.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-top: 2rem;
}

.arrowButton {
  background-color: #00000000;
  border: 0px;

}
.arrows {
  width: 2rem;
}
@media screen and (max-width: 600px) {
  .featuredContainer {
    margin-left: -1.2rem;
  
  }

  .featuredTitle {
    margin-left: 2rem;
  }
  
}