@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

.slide {
    display: flex;
    flex-direction: column;
}

.logo {
    width: 100%;
    font-size: 36px;
    font-weight: 900;
    color: #1C4B82;
    align-self: flex-start;
    margin: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Links {
    width: 80%;
    flex: 2;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.Links a {
    text-decoration: none;
    margin: 10px 5px;
}

.nav_btn {
    font-size: 22px;
    color: #1C4B82;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}

.nav_btn_special {
    composes: nav_btn;
    border: 2px solid #FFA41B;
    padding: 5px;
    border-radius: 14px;
}

.nav_btn_special:hover {
    border: 2px solid #1C4B82;
    color: #FFA41B;
    padding: 5px;
    border-radius: 14px;
}