@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
    margin: 0 5px;
}


/*///////////////////////*/

.body {
    background-color: rgb(186, 186, 186);
    height: 100vh;
}

.DisplayRoom {
    background-color: white;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.16);
    width: 80%;
    height: 100%;
    margin: auto;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.16);
    margin-bottom: 5px;
}

.Params {
    display: flex;
    width: 100%;
    height: 89%;
    margin: 0 5px
}

.list {
    width: 23%;
    height: 100%;
    background-color: rgb(245, 245, 245);
}

.DivCourse {
    width: 96%;
    height: 50px;
    margin: 5px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(229, 229, 229);
}

.Course {
    width: 95%;
    cursor: pointer;
}

.video {
    width: 76%;
    margin: none auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.Vid {
    width: 98%;
    margin: none auto;
}

.NoVid {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.NoVid p {
    margin: 20px none;
}


/*/////////////////////////////*/

.MotherDivCourse {
    width: 100%;
    height: auto;
    margin-top: 20px;
    background-color: #B9E5FDff;
}

.containerContactBlocks {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BorderSectionsContact {
    width: 45%;
    height: 500px;
    margin: 50px auto;
    border: 5px solid #1C4B82;
    border-radius: 15px;
    background-color: white;
}

.TitleContact {
    text-align: center;
    margin-top: 5px;
    color: #000000;
}

.ContactPartInfoTextField {
    width: 90%;
    height: 20%;
    border: 1px dashed rgb(125, 125, 125);
    border-radius: 15px;
    margin-top: 10px;
}

.ContactPartInfoTextFieldInter {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 30px auto;
    gap: 10px
}

.fileDisplay {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer
}

.name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}