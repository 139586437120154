* {
  margin: none;
  padding: none;
}

.MainSection {
  display: grid;
  grid-template-columns: 1fr 4fr;
  height: 100vh;
  width: 100%;
  position: fixed;
}

.logo {
  flex: 1;
  font-size: 36px;
  font-weight: 800;
  color: #1c4b82;
  align-self: center;
}

.leftSection {
  /* background-color: blue; */
  height: 100vh;
  box-shadow: 2px 0 4px 0px rgba(0, 0, 0, 0.16);
}

.leftSectionNav {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 64px;
}

.leftSectionNav h1 {
  margin: 0 20px;
}

.rightSection {
  height: 100%;
}

.ComponentContainer {
  width: 100%;
  height: 100%;
  max-height: 92vh;
  overflow-y: auto;
  overflow: auto;
  display: block;
}
