.big {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.titre {
    display: flex;
    justify-content: center;
    align-items: center;
}