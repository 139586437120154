@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/*////////////SECTION ONE/////////////////*/

.containerimage {
  background-color: #f9f9f9;
}
.motivationImg {
  display: flex;
  flex-direction: row;
  background-color: #3e467800;

  justify-content: space-between;
  /* padding-left: 5%;
    padding-right: 5%; */
  width: 100%;
  margin-top: 20px;
  gap: 20px;
}

/*////////////SECTION TWO/////////////////*/

.sectionTwo {
  /* position: absolute; */

  width: 100%;
  background-color: #ca3260;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  /* background-color: rgba(70, 145, 232, 0.08); */
}

.explore_container {
  width: 50%;
  height: 76px;
  border-radius: 10px;
  box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 3%;
  border: 1 solid;
}

.explore_btn {
  background-color: transparent;
  border: none;
  color: #1c4b82;
  font-size: 25px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  width: 40%;
  cursor: pointer;
  position: relative;
}

.explore_line {
  width: 1px;
  height: 38px;
  margin-block: auto;
  background-color: #9f9f9f;
}
@media (max-width: 450px) {
  .explore_line {
    width: 1px;
    height: 26px;
    margin-block: auto;
    background-color: #9f9f9f;
  }
}
@media (min-width: 450px) and (max-width: 577px) {
  .explore_line {
    width: 1px;
    height: 26px;
    margin-block: auto;
    background-color: #9f9f9f;
  }
}

.explore_input {
  background-color: white;
  border: none;
  padding-left: 24px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  color: #7589a0;
  width: calc(100% - 20%);
}

/* For small screens (phones) */
@media (max-width: 576px) {
  .explore_input {
    font-size: 11px; /* Adjust font size for small screens */
    padding-left: 20px; /* Adjust padding for small screens */
    width: calc(100% - 10%); /* Adjust width for small screens */
  }
}

/* For medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .explore_input {
    font-size: 16px; /* Adjust font size for tablets */
    padding-left: 23px; /* Adjust padding for tablets */
    width: calc(100% - 15%); /* Adjust width for tablets */
  }
}

/* For large screens (small desktops) */
@media (min-width: 769px) and (max-width: 1024px) {
  .explore_input {
    font-size: 19px; /* Keep the original font size for small desktops */
    padding-left: 24px; /* Keep the original padding for small desktops */
    width: 70%; /* Keep the original width for small desktops */
  }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) {
  .explore_input {
    font-size: 20px; /* Keep the original font size for large desktops */
    padding-left: 24px; /* Keep the original padding for large desktops */
    width: calc(100% - 20%); /* Keep the original width for large desktops */
  }
}

.explore_input::placeholder {
  font-size: 20px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  color: #9f9f9f;
}

@media (max-width: 349px) {
  .explore_input::placeholder {
    font-size: 9px; /* Ajuster la taille de la police pour les petits écrans */
  }
}
/* Pour les petits appareils (téléphones) */
@media (min-width: 350px) and (max-width: 576px) {
  .explore_input::placeholder {
    font-size: 12px; /* Ajuster la taille de la police pour les petits écrans */
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 768px) {
  .explore_input::placeholder {
    font-size: 14px; /* Ajuster la taille de la police pour les tablettes */
  }
}

/* Pour les petits desktops */
@media (min-width: 769px) and (max-width: 992px) {
  .explore_input::placeholder {
    font-size: 19px; /* Ajuster la taille de la police pour les petits desktops */
  }
}

/* Pour les moyens desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .explore_input::placeholder {
    font-size: 20px; /* Taille de la police pour les moyens desktops */
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) {
  .explore_input::placeholder {
    font-size: 20px; /* Taille de la police pour les grands desktops */
  }
}

.explore_input:focus {
  outline: none;
}

.search_btn {
  background-color: transparent;
  border: none;
  width: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*////////////SECTION THREE/////////////////*/

.sectionThree {
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  /* padding-left: 6.5rem;
   padding-right: 6.5rem; */
  background-color: rgba(0, 255, 255, 0);
  /* width: 100%; */
  padding-bottom: 5%;
}

.topTrainingElements {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  /* width: 100%; */
  /* padding-bottom: 5rem; */
}

/* Add this media query */

.carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  gap: calc(4vw + 5px);
  /* flex-direction: row; */
  align-self: center;
  /* width: 1400px; */
  /* background-color: rgb(107, 226, 43); */
  margin-left: 6%;
  margin-right: 7%;
}
@media (max-width: 300px) {
  .carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: calc(1vw + 20px);
    /* flex-direction: row; */
    align-self: center;
    /* width: 1400px; */
    /* background-color: rgb(107, 226, 43); */
    margin-left: 4%;
    margin-right: 5%;
  }
}
@media (min-width: 300px) and (max-width: 580px) {
  .carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: calc(2vw + 10px);
    /* flex-direction: row; */
    align-self: center;
    /* width: 1400px; */
    /* background-color: rgb(107, 226, 43); */
    margin-left: 5%;
    margin-right: 6%;
  }
}
@media (min-width: 580px) and (max-width: 1300px) {
  .carousel {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    gap: calc(4vw + 5px);
    /* flex-direction: row; */
    align-self: center;

    /* width: 100%; */
    box-shadow: #706f6f;

    background-color: rgba(255, 0, 0, 0);
    /* flex: 1; */
  }
}

.inner_carousel {
  width: clamp(300px, 20vw, 400px); /* Adjusts between 150px and 255px */
  height: clamp(200px, 20vw, 300px);
  border-radius: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 2px 2px 12px #0707072d;
}

.inner_carousel:last-child {
  margin-right: 0; /* Remove margin from the last item */
}

.categorietype {
  background-color: #fdf4a8;
  border-radius: 8px;
  font-family: "Lato";
  font-size: 13px;
  font-weight: bold;
  padding: 10px;
  white-space: normal;
  color: #2c2c2c;
}

.categoridomain {
  color: #343434;
  border-radius: 8px;
  font-family: "Lato";
  font-size: 13px;
  font-weight: bold;
  margin: 20px;
  justify-content: space-between;
}

.categoriniveau {
  color: #706f6f;
  border-radius: 8px;
  font-family: "Lato";
  font-size: 13px;
  font-weight: bold;
  margin-left: 20px;
  justify-content: space-between;
}

.categoriprice {
  color: #343434;
  border-radius: 8px;
  font-family: "Nutino";
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
  justify-content: space-between;
}

.categorirating {
  font-family: "Nutino";
  font-size: 10px;
  font-weight: bold;
  color: #818181;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px;
}

.carousel::-webkit-scrollbar {
  height: 8px;
}

.carousel::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Roundness of the scrollbar thumb */
}

.carousel::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color when hovering over the scrollbar thumb */
}

.arrowButton:hover img {
  /* Add styles for hover effect on the arrow image */
  filter: brightness(0.8);
  /* Decrease brightness */
}

.imagebackground {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 65vmax;
  background-color: #f9f9f9;
}

.image {
  width: 100%; /* Example width */
  height: 150px; /* Example height */
  overflow: hidden;
}

.imagefeatures {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0px 10px 0px 10px;
}

/* Arrow button styles */
.arrowButton {
  background-color: #00000000;
  border: 0px;
  width: 50%;
}

/* 
.arrowButton:hover {
    background-color: #9c9191;
} */

.imgTop {
  border-bottom: 1px solid #706f6f5a;
  height: 60%;
  object-fit: cover;
  border-radius: 0px 12px 0 0;
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.25);
}

.infoCourse {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.card_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* background-color: #345AA4; */
}

.category_tag {
  margin: 5px 0;
  background-color: rgba(255, 214, 0, 0.5);
  width: fit-content;
}

.category {
  font-family: "Nunito", sans-serif;
  font-size: 11px;
  font-weight: 700;
  color: #000000;
  margin: 5px 15px;
  font-family: "Lato", sans-serif;
  /*border-top: 1px solid #E0E0E0;*/
}

.card_price {
  font-family: "Nunito", sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: #343434;
}

.cardLevel {
  width: 100%;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-weight: 700;
  color: #706f6f;
}

.cardTitle {
  max-width: 280px;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  font-weight: bolder;
  color: #343434;
  width: 100%;
  align-self: flex-start;
}

.cardCenterHr {
  background-color: #e0e0e0;
  width: 150px;
  height: 1px;
  border: none;
  border-radius: 50px;
  align-self: center;
}

.card_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.rating_text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: bold;
  color: #818181;
}

.card_footer_type {
  align-self: flex-start;
  background-color: #d7d9f4;
  color: black;
  font-weight: 600;
  width: 100px;
  height: 30px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.card_footer_subscribers {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscribers_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  /* background-color: #0E2256; */
}

.user_avatar_subscribers {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* background-color: red; */
}

.z_effect {
  left: -3px;
}

.z_effect2 {
  left: -6px;
}

.user_avatar_subscribers img {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.subscribers_text {
  font-size: 11px;
  font-weight: bold;
  color: #818181;
  font-family: "Nunito", sans-serif;
}

/*////////////SECTION FOUR/////////////////*/

.sectionFour {
  background-color: rgba(7, 225, 232, 0.08);
  width: 100%;

  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.sectionFour h1 {
  margin: 20px 100px;

  font-size: 36px;
  color: #1c4b82;

  font-weight: bolder;

  text-align: left;
}

/*//////////////section Five prime //////////////////////*/

.sectionFivePrime {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  padding: 50px 0;
  gap: 80px;
}

.sectionFivePrime_title h1 {
  font-size: 36px;
  color: #1c4b82;
  text-transform: uppercase;
  font-family: "Nunito";
  font-weight: bold;
}

.OurTrainers {
  width: 84.5%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
}

.OurTrainers_element {
  width: 100%;
  max-width: 350px;
  height: 309px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.OurTrainers_element_back {
  position: absolute;
  /* background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.716)); */
  height: 80px;
  width: 100%;
  max-width: 309px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  transition: height 0.4s ease-in-out;
  bottom: 0;
}

.OurTrainers_element_back:hover {
  height: 100%;
}

.OurTrainers_element_txt {
  padding: 10px;
  text-align: center;
  width: 100%;
}

.OurTrainers_element_txt h1 {
  margin-top: 20px;
  color: white;
  font-size: 15px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.OurTrainers_element_txt h2 {
  color: #a7a7a7;
  font-size: 10px;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
}

.OurTrainers_element_img {
  width: 100%;
  border-radius: 15px;
  height: 350px;
  object-fit: cover;
}

/*////////////SECTION FIVE/////////////////*/

.sectionFive {
  width: 84.5%;
  height: 60vh;
  min-height: 380px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.consultant_txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.consultant_txt_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.consultant_txt_header_bloc {
  background-color: #1c4b82;
  color: white;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  font-family: "Nunito";
  padding: 10px;
  border-radius: 4px;
}

.consultant_txt_header_normal {
  color: #1c4b82;
  font-size: 40px;
  font-weight: 800;
  text-align: center;
  font-family: "Nunito";
}

.consultant_txt h2 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  font-family: "Nunito";
}

.consultant_buttons {
}

.ApplyButton {
  width: 220px;
  height: 55px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  color: #1c4b82;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  font-family: "Nunito";
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ApplyButton:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
}

/*////////////SECTION SIX/////////////////*/

.sectionSix {
  width: 100%;
  height: 300px;
  min-height: 300px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  background-color: rgba(70, 145, 232, 0.08);
}

.sectionSix h1 {
  font-size: 36px;
  font-family: "Nunito";
  font-weight: bolder;
  color: #1c4b82;
  text-transform: uppercase;
}

.OurPartners {
  width: 84.5%;
  height: 60%;
  border-radius: 44px;
  display: inline-block;
}

.OurPartners div {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OurPartners div img {
  max-width: 150px;
  margin: 20px;
  filter: grayscale(1);
}

.OurPartners div img:hover {
  filter: grayscale(0);
}

.flex {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.textsearch {
  color: white;

  font-size: 14px;
  padding-top: 2%;
  font-family: "Lato";
}
/* For very small screens (phones) */
@media (max-width: 345px) {
  .textsearch {
    font-size: 6px; /* Adjust font size for very small screens */
    padding-top: 0; /* Adjust padding for very small screens */
    /* width: 130%; */
    margin-left: -5%;
  }
}

/* For very small screens (phones) */
@media (min-width: 345px) and (max-width: 460px) {
  .textsearch {
    font-size: 7px; /* Adjust font size for very small screens */
    padding-top: 0; /* Adjust padding for very small screens */
    /* width: 130%; */
    margin-left: -5%;
  }
}

@media (min-width: 460px) and (max-width: 576px) {
  .textsearch {
    font-size: 8px; /* Adjust font size for small screens */
    padding-top: 0; /* Adjust padding for small screens */
  }
}

/* For medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .textsearch {
    font-size: 11px; /* Adjust font size for tablets */
    padding-top: 0;
  }
}

/* For large screens (small desktops) */
@media (min-width: 769px) and (max-width: 1024px) {
  .textsearch {
    font-size: 15px; /* Adjust font size for small desktops */
    padding-top: 0; /* Adjust padding for small desktops */
  }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) {
  .textsearch {
    font-size: 18px; /* Keep the original font size for large desktops */
    padding-top: 0; /* Keep the original padding for large desktops */
  }
}
@media (min-width: 1240px) {
  .textsearch {
    font-size: 14px; /* Keep the original font size for large desktops */
    padding-top: 0; /* Keep the original padding for large desktops */
    padding-bottom: 6px;
  }
}
@media (min-width: 2500px) {
  .textsearch {
    font-size: 20px; /* Keep the original font size for large desktops */
    padding-top: 0; /* Keep the original padding for large desktops */
  }
}
.textsearch2 {
  color: white;

  font-size: 14px;
  padding-top: 2%;
  font-family: "Lato";
}
@media (max-width: 460px) {
  .textsearch2 {
    font-size: 7px; /* Adjust font size for very small screens */
    padding-top: 0; /* Adjust padding for very small screens */
    /* width: 130%; */
    margin-left: 33%;
    margin-top: 2%;
  }
}
@media (min-width: 460px) and (max-width: 576px) {
  .textsearch2 {
    font-size: 8px; /* Keep the original font size for large desktops */
    padding-top: 7; /* Keep the original padding for large desktops */
    padding-bottom: 6px;
    margin-left: 42%;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .textsearch2 {
    font-size: 11px; /* Keep the original font size for large desktops */
    padding-top: 7; /* Keep the original padding for large desktops */
    padding-bottom: 6px;
    margin-left: 43%;
  }
}
@media (min-width: 768px) and (max-width: 800px) {
  .textsearch2 {
    font-size: 14px; /* Keep the original font size for large desktops */
    padding-top: 7; /* Keep the original padding for large desktops */
    padding-bottom: 6px;
    margin-left: 44%;
  }
}
@media (min-width: 800px) and (max-width: 1000px) {
  .textsearch2 {
    font-size: 14px; /* Keep the original font size for large desktops */
    padding-top: 7; /* Keep the original padding for large desktops */
    padding-bottom: 6px;
    margin-left: 44%;
  }
}
@media (min-width: 1000px) and (max-width: 1242px) {
  .textsearch2 {
    font-size: 16px; /* Keep the original font size for large desktops */
    padding-top: 7; /* Keep the original padding for large desktops */
    padding-bottom: 6px;
    margin-left: 45%;
  }
}

.textsearchtitle {
  color: white;
  font-size: 48px;
  font-weight: 800px;
}

.features {
  color: white;
  font-family: "Lato";
  font-size: 40px;
  font-weight: bolder;
  padding-bottom: 50px;
  font-weight: 800; /* Augmentation du poids de la police */
}

.underline {
  width: 10%;
  background-color: #cd6214;
  padding-top: 5px;
  border-radius: 100px;
  margin-top: 20px;
}

.categorie {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
}

.featurescarousel {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: #1c4b82;
}

/*media*/

@media (max-width: 1300px) {
  .textsearchtitle {
    color: rgb(13, 14, 13);
    font-size: 30px;
    font-weight: 500px;
    align-content: center;
    align-self: center;
    /* margin-right: 50px;
   margin-left: 200px; */
  }

  .underline {
    width: 10%;
    background-color: #cd6214;
    padding-top: 3px;
    border-radius: 100px;
    margin-top: 10px;
  }

  .features {
    color: white;
    font-family: "Lato";
    font-size: 20px;
    font-weight: bolder;
    /* padding-bottom: 10px; */
    font-weight: 800; /* Augmentation du poids de la police */
  }

  .sectionThree {
    /* width: 100%; */

    /* padding-right: 30px; */
    /* margin: 0px auto; */
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    /* padding: 60px; */
    background-color: #0e74e900;
    margin-top: -50px;
    margin-bottom: 8%;
  }

  .imagebackground {
    position: absolute;
    z-index: -10000;
    width: 100%;
    height: 80vh;
  }

  .topTrainingElements {
    display: flex;
    flex-direction: row;
    /* padding: 1rem; */
    /* padding-left: 30px;
       padding-right: 30px; */
    background-color: rgba(9, 240, 29, 0);
    /* width: 100%; */
    /* padding-bottom: 5rem; */
    /* margin-left: -50px; */
    align-items: center;
    justify-content: center;
  }

  .imagefeatures {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0px 10px 0px 10px;
  }

  .inner_carousel {
    /* width: 20em; */
    /*height: 60px; */
    border-radius: 0px 10px 0px 10px;
    background-color: white;
  }

  .arrowButton {
    background-color: #00000000;
    border: 0px;
    width: 50%;
  }
  .image {
    width: 100%; /* Example width */

    overflow: hidden;
  }
}

@media (max-width: 700px) {
  .textsearchtitle {
    color: white;
    font-size: 20px;
    font-weight: 600px;
    align-content: center;
    align-self: center;
    /* margin-right: 50px;
margin-left: 200px; */
  }

  .imagebackground {
    position: absolute;
    z-index: -10000;
    width: 100%;
    height: 500px;
  }

  .imagefeatures {
    width: 200px;
    height: 100px;
    /* height: 100%; */
  }

  .imagefeatures img {
    width: 200px;
    height: 100px;
    border-radius: 50px;
  }

  .textcontainer {
    display: flex;
    flex-direction: column;

    justify-content: center;
    margin-top: -3%;
  }

  .sectionThree {
    width: 100%; /* Full width */
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0%;
    display: flex;
    flex-direction: column;
    background-color: #1c4c8200; /* Transparent background color */
    flex: 1;
  }
  /* Media Queries for Responsive Design */
  @media (max-width: 470px) {
    .sectionThree {
      padding-left: 15px; /* Adjust padding for extra small screens */
      padding-right: 15px;
      margin-bottom: 0; /* Adjust margin for extra small screens */
    }
  }

  @media (min-width: 471px) and (max-width: 629px) {
    .sectionThree {
      padding-left: 20px; /* Adjust padding for small screens */
      padding-right: 20px;
      margin-bottom: 1rem; /* Adjust margin for small screens */
    }
  }

  @media (min-width: 630px) and (max-width: 768px) {
    .sectionThree {
      padding-left: 25px; /* Adjust padding for tablets */
      padding-right: 25px;
    }
  }

  @media (min-width: 769px) and (max-width: 1300px) {
    .sectionThree {
      padding-left: 30px; /* Adjust padding for small desktops */
      padding-right: 30px;
    }
  }

  @media (min-width: 1300px) {
    .sectionThree {
      padding-left: 30px; /* Keep original padding for large desktops */
      padding-right: 30px;
      margin-top: 0%;
    }
  }
}

.features {
  color: white;
  font-family: "Lato";
  font-size: 12px;
  font-weight: bolder;
  padding-bottom: 10px;
}

.topTrainingElements {
  display: flex;
  flex-direction: row; /* Arrange items in a row by default */

  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
}
/* Media Queries for Responsive Design */
@media (max-width: 576px) {
  .topTrainingElements {
    flex-direction: column; /* Stack items vertically on small screens */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .topTrainingElements {
    /* Adjust styles for medium screens if needed */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .topTrainingElements {
    /* Adjust styles for large screens if needed */
  }
}

@media (min-width: 1025px) {
  /* Keep the original styles for extra large screens */
}

.carousel {
  /* padding-left:10px;
       padding-right: 10px; */
  font-family: "Lato";
}

.inner_carousel {
  width: clamp(120px, 21vw, 180px); /* Adjusts between 150px and 255px */
  height: clamp(215px, 20vw, 100px);
  border-radius: 0px 10px 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.inner_carousel:last-child {
  margin-right: 0; /* Remove margin from the last item */
}
.categorie {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2px;
}

.categorietype {
  background-color: #fdf4a8;
  border-radius: 8px;
  font-family: "Lato", sans-serif; /* Assurez-vous que la police 'Lato' est correctement référencée */
  font-size: 9px;
  font-weight: bold;
  white-space: normal;
  margin: 0px 0px 0px 15px;
}
@media (max-width: 320px) {
  .categorietype {
    font-size: 5px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px -8px 0px 1px; /* Ajuster les marges pour les tablettes */
  }
}
@media (min-width: 320px) and (max-width: 370px) {
  .categorietype {
    font-size: 4px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px -13px 0px 1px; /* Ajuster les marges pour les tablettes */
  }
}
@media (min-width: 370px) and (max-width: 406px) {
  .categorietype {
    font-size: 4px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px -8px 0px 3px; /* Ajuster les marges pour les tablettes */
  }
}

@media (min-width: 406px) and (max-width: 436px) {
  .categorietype {
    font-size: 4.5px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px -10px 0px 2px; /* Ajuster les marges pour les tablettes */
  }
}
@media (min-width: 436px) and (max-width: 460px) {
  .categorietype {
    font-size: 6.5px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px -5px 0px 2px; /* Ajuster les marges pour les tablettes */
  }
}

@media (min-width: 460px) and (max-width: 503px) {
  .categorietype {
    font-size: 6.5px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px 0px 0px 22px; /* Ajuster les marges pour les tablettes */
  }
}

/* Pour les petits appareils (téléphones) */
@media (min-width: 503px) and (max-width: 582px) {
  .categorietype {
    font-size: 7px; /* Ajuster la taille de la police pour les petits écrans */
    margin: 0px 0px 0px 24px;
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 582px) and (max-width: 618px) {
  .categorietype {
    font-size: 7px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px 0px 0px 28px;
  }
}
@media (min-width: 618px) and (max-width: 700px) {
  .categorietype {
    font-size: 8.5px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px 0px 0px 28px;
  }
}
@media (min-width: 700px) and (max-width: 800px) {
  .categorietype {
    font-size: 8.5px; /* Ajuster la taille de la police pour les tablettes */
    margin: 0px 0px 0px 28px;
  }
}

/* Pour les petits desktops */
@media (min-width: 800px) and (max-width: 992px) {
  .categorietype {
    font-size: 7px; /* Ajuster la taille de la police pour les petits desktops */
    margin: 0px 0px 0px 28px;
  }
}

/* Pour les moyens desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .categorietype {
    font-size: 9px; /* Ajuster la taille de la police pour les moyens desktops */
    margin-left: 8px;
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) {
  .categorietype {
    font-size: 9px; /* Ajuster la taille de la police pour les grands desktops */
    margin: 0px 0px 0px 15px; /* Ajuster les marges pour les grands desktops */
  }
}

.categoridomain {
  color: #343434;
  border-radius: 8px;
  font-family: "Lato", sans-serif; /* Assurez-vous que la police 'Lato' est correctement référencée */
  font-size: 13px;
  font-weight: bold;
  padding: 0px;
  margin: 2px 0px 0px 17px;
}

/* Pour les petits appareils (téléphones) */
@media (max-width: 576px) {
  .categoridomain {
    font-size: 10px; /* Ajuster la taille de la police pour les petits écrans */
    margin: 2px 0px 6px 14px; /* Ajuster les marges pour les petits écrans */
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 700px) {
  .categoridomain {
    font-size: 12px; /* Ajuster la taille de la police pour les tablettes */
    margin: 2px 0px 0px 18px; /* Ajuster les marges pour les tablettes */
  }
}
@media (min-width: 700px) and (max-width: 800px) {
  .categoridomain {
    font-size: 12px; /* Ajuster la taille de la police pour les tablettes */
    margin: 2px 0px 0px 0px; /* Ajuster les marges pour les tablettes */
  }
}

/* Pour les petits desktops */
@media (min-width: 800px) and (max-width: 992px) {
  .categoridomain {
    font-size: 13px; /* Ajuster la taille de la police pour les petits desktops */
    margin: 2px 0px 0px -1px; /* Ajuster les marges pour les petits desktops */
  }
}

/* Pour les moyens desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .categoridomain {
    font-size: 13px; /* Ajuster la taille de la police pour les moyens desktops */
    margin: 2px 0px 0px -7px; /* Ajuster les marges pour les moyens desktops */
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) {
  .categoridomain {
    font-size: 13px; /* Ajuster la taille de la police pour les grands desktops */
    margin: 2px 0px 0px 17px; /* Ajuster les marges pour les grands desktops */
  }
}

.categoriniveau {
  color: #706f6f;
  border-radius: 8px;
  font-family: "Lato", sans-serif; /* Assurez-vous que la police 'Lato' est correctement référencée */
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  margin: 2px 0px 0px 15px;
}

/* Pour les petits appareils (téléphones) */
@media (max-width: 576px) {
  .categoriniveau {
    font-size: 8px; /* Ajuster la taille de la police pour les petits écrans */
    padding: 4px; /* Ajuster le padding pour les petits écrans */
    margin: 2px 0px 0px 10px; /* Ajuster les marges pour les petits écrans */
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 700px) {
  .categoriniveau {
    font-size: 8px; /* Ajuster la taille de la police pour les tablettes */
    padding: 5px; /* Ajuster le padding pour les tablettes */
    margin: 2px 0px 0px 12px; /* Ajuster les marges pour les tablettes */
  }
}
@media (min-width: 700px) and (max-width: 800px) {
  .categoriniveau {
    font-size: 11px; /* Ajuster la taille de la police pour les petits desktops */
    padding: 5px; /* Ajuster le padding pour les petits desktops */
    margin: 2px 0px 0px -6px; /* Ajuster les marges pour les petits desktops */
  }
}

/* Pour les petits desktops */
@media (min-width: 800px) and (max-width: 992px) {
  .categoriniveau {
    font-size: 11px; /* Ajuster la taille de la police pour les petits desktops */
    padding: 5px; /* Ajuster le padding pour les petits desktops */
    margin: 2px 0px 0px -6px; /* Ajuster les marges pour les petits desktops */
  }
}

/* Pour les moyens desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .categoriniveau {
    font-size: 10px; /* Ajuster la taille de la police pour les moyens desktops */
    padding: 5px; /* Ajuster le padding pour les moyens desktops */
    margin: 2px 0px 0px -12px; /* Ajuster les marges pour les moyens desktops */
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) {
  .categoriniveau {
    font-size: 9px; /* Ajuster la taille de la police pour les grands desktops */
    padding: 5px; /* Ajuster le padding pour les grands desktops */
    margin: 2px 0px 0px 15px; /* Ajuster les marges pour les grands desktops */
  }
}

.categoriprice {
  color: #343434;
  border-radius: 8px;
  font-family: "Nunito", sans-serif; /* Assurez-vous que la police 'Nunito' est correctement référencée */
  font-size: 10px;
  font-weight: bold;
  justify-content: space-between;
  margin: 2px 0px 0px 74px;
  padding: 2px;
}
@media (max-width: 320px) {
  .categoriprice {
    font-size: 7px; /* Ajuster la taille de la police pour les petits écrans */
    margin: 2px 0px 0px 15px; /* Ajuster les marges pour les petits écrans */
    padding: 2px;
  }
}

@media (min-width: 320px) and (max-width: 460px) {
  .categoriprice {
    font-size: 7px; /* Ajuster la taille de la police pour les petits écrans */
    margin: 2px 0px 0px 15px; /* Ajuster les marges pour les petits écrans */
    padding: 2px;
  }
}

/* Pour les petits appareils (téléphones) */
@media (min-width: 460px) and (max-width: 576px) {
  .categoriprice {
    font-size: 10px; /* Ajuster la taille de la police pour les petits écrans */
    margin: 2px 0px 0px 14px; /* Ajuster les marges pour les petits écrans */
    padding: 2px;
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 768px) {
  .categoriprice {
    font-size: 11px; /* Ajuster la taille de la police pour les tablettes */
    margin: 2px 0px 0px 40px; /* Ajuster les marges pour les tablettes */
    padding: 2px;
  }
}

/* Pour les petits desktops */
@media (min-width: 769px) and (max-width: 992px) {
  .categoriprice {
    font-size: 10px; /* Ajuster la taille de la police pour les petits desktops */
    margin: 2px 0px 0px 40px; /* Ajuster les marges pour les petits desktops */
    padding: 2px;
  }
}

/* Pour les moyens desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .categoriprice {
    font-size: 12px; /* Ajuster la taille de la police pour les moyens desktops */
    margin: 2px 0px 0px 21px; /* Ajuster les marges pour les moyens desktops */
    padding: 2px;
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) {
  .categoriprice {
    font-size: 12px; /* Ajuster la taille de la police pour les grands desktops */
    margin: 2px 0px 0px 74px; /* Ajuster les marges pour les grands desktops */
    padding: 2px;
  }
}

.categorirating {
  font-family: "Nutino";
  font-size: 3px;
  font-weight: bold;
  color: #818181;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* margin: 20px; */
  padding: 10px;
  margin: 0px;
}

.explore_container {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 3%;
  border: 1 solid;
  margin-left: 30px;
  margin-right: 30px;
}

.container {
  width: 100%;
  align-items: center;
  align-self: center;
}

.explore_btn {
  background-color: transparent;
  border: none;
  color: #1c4b82;
  font-size: 15px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  width: 40%;
  cursor: pointer;
  position: relative;
}

.motivationImg {
  display: flex;
  flex-direction: row;
  background-color: #3e467800;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  align-content: center;
  /* padding-left: 5%;
   padding-right: 5%; */
  width: 100%;
  margin-top: 0;
  gap: 5px;
}

.arrowButton {
  background-color: #00000000;
  border: none; /* Ajuster selon les besoins */
  width: 50%;
}

/* Pour les petits appareils (téléphones) */
@media (max-width: 576px) {
  .arrowButton {
    width: 100%; /* Largeur ajustée pour les petits écrans */
    margin-top: 60px;
  }
}
/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 768px) {
  .arrowButton {
    width: 80%; /* Largeur ajustée pour les tablettes */
    margin-top: 60px;
  }
}
@media (min-width: 769px) and (max-width: 950px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}
@media (min-width: 950px) and (max-width: 1000px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}
@media (min-width: 1000px) and (max-width: 1050px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}
@media (min-width: 1050px) and (max-width: 1100px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}
@media (min-width: 1100px) and (max-width: 1150px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 1150px) and (max-width: 1180px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les tablettes */
    margin-top: 60px;
  }
}

/* Pour les petits desktops */
@media (min-width: 1180px) and (max-width: 1216px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}
@media (min-width: 1216px) and (max-width: 1270px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les petits desktops */
    margin-top: 60px;
  }
}

/* Pour les moyens desktops */
@media (min-width: 1270px) and (max-width: 1287px) {
  .arrowButton {
    width: 40%; /* Largeur ajustée pour les moyens desktops */
    margin-top: 60px;
  }
}

/* Pour les grands desktops */
@media (min-width: 1287px) {
  .arrowButton {
    width: 50%; /* Largeur ajustée pour les grands desktops */
    margin-top: 60px;
  }
}

/* Styles pour .arrowButton2 */
.arrowButton2 {
  background-color: #00000000;
  border: none; /* Ajuster selon les besoins */
  width: 50%;
}

/* Pour les petits appareils (téléphones) */
@media (max-width: 576px) {
  .arrowButton2 {
    width: 100%; /* Largeur ajustée pour les petits écrans */
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 768px) {
  .arrowButton2 {
    width: 80%; /* Largeur ajustée pour les tablettes */
  }
}
@media (min-width: 768px) and (max-width: 950px) {
  .arrowButton2 {
    width: 50%; /* Largeur ajustée pour les petits desktops */
  }
}
@media (min-width: 950px) and (max-width: 1000px) {
  .arrowButton2 {
    width: 50%; /* Largeur ajustée pour les petits desktops */
  }
}
@media (min-width: 1000px) and (max-width: 1050px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les petits desktops */
  }
}
@media (min-width: 1050px) and (max-width: 1100px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les petits desktops */
  }
}
@media (min-width: 1100px) and (max-width: 1150px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les petits desktops */
  }
}
/* Pour les petits desktops */
@media (min-width: 1150px) and (max-width: 1180px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les petits desktops */
  }
}
/* Pour les petits desktops */
@media (min-width: 1180px) and (max-width: 1216px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les petits desktops */
  }
}
@media (min-width: 1216px) and (max-width: 1270px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les petits desktops */
  }
}

/* Pour les moyens desktops */
@media (min-width: 1270px) and (max-width: 1300px) {
  .arrowButton2 {
    width: 40%; /* Largeur ajustée pour les moyens desktops */
  }
}

/* Pour les grands desktops */
@media (min-width: 13001px) {
  .arrowButton2 {
    width: 50%; /* Largeur ajustée pour les grands desktops */
  }
}

.arrowButton:hover {
  background-color: #9c9191;
}

.textsearchtitle {
  color: white;
  font-size: 47px;
  font-weight: 600;
  text-align: center; /* Align text to the center */
  margin: 0 auto; /* Center the element horizontally */
}

/* For small screens (phones) */
@media (max-width: 375px) {
  .textsearchtitle {
    font-size: 15px; /* Adjust font size for small screens */
    margin: 10px 0; /* Add some vertical margin */
  }
}
/* For small screens (phones) */
@media (min-width: 375px) and (max-width: 576px) {
  .textsearchtitle {
    font-size: 18px; /* Adjust font size for small screens */
    margin: 10px 0; /* Add some vertical margin */
  }
}

/* For medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .textsearchtitle {
    font-size: 27px; /* Adjust font size for tablets */
    margin: 15px 0; /* Add some vertical margin */
  }
}

/* For large screens (small desktops) */
@media (min-width: 769px) and (max-width: 1024px) {
  .textsearchtitle {
    font-size: 40px; /* Adjust font size for small desktops */
    margin: 20px 0; /* Add some vertical margin */
  }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) and (max-width: 1240px) {
  .textsearchtitle {
    font-size: 47px; /* Keep the original font size for large desktops */
    margin: 12px 0; /* Add some vertical margin */
  }
}
@media (min-width: 1240px) and (max-width: 1300px) {
  .textsearchtitle {
    font-size: 40px; /* Keep the original font size for large desktops */
    margin: 12px 0; /* Add some vertical margin */
    font-weight: 550; /* Augmentation du poids de la police */
    margin-right: 3%;
  }
}

.features {
  color: white; /* White text color */
  font-family: "Lato", sans-serif; /* Lato font family */
  font-size: 30px; /* Base font size */
  font-weight: bolder; /* Bold font weight */
  margin-bottom: 0; /* Bottom margin */

  margin-left: 5%;
}

@media (max-width: 270px) {
  .features {
    font-size: 8px;
    margin-top: 6rem;
    margin-left: -6%; /* Adjust font size for small screens */
    margin-bottom: 5%;
  }
}
@media (min-width: 270px) and (max-width: 300px) {
  .features {
    font-size: 11px;
    margin-top: 34%;
    margin-left: -4%; /* Adjust font size for small screens */
    margin-bottom: 5%;
  }
}
@media (min-width: 300px) and (max-width: 356px) {
  .features {
    font-size: 12px;
    margin-top: 32%;
    margin-left: -3%; /* Adjust font size for small screens */
    margin-bottom: 5%;
  }
}
@media (min-width: 356px) and (max-width: 470px) {
  .features {
    font-size: 12px;
    margin-top: 24%;
    margin-left: 1%; /* Adjust font size for small screens */
    margin-bottom: 3%;
  }
}

@media (min-width: 471px) and (max-width: 576px) {
  .features {
    font-size: 16px;
    margin-top: 20%;
    margin-left: 1%; /* Adjust font size for small screens */
    margin-bottom: 5%;
  }
}

/* For medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .features {
    font-size: 21px; /* Adjust font size for tablets */
    margin-top: 14%;
    margin-left: 1%;
    font-weight: 800; /* Augmentation du poids de la police */
    margin-bottom: 4%;
  }
}

/* For large screens (small desktops) */
@media (min-width: 769px) and (max-width: 1240px) {
  .features {
    font-size: 25px; /* Adjust font size for small desktops */
    margin-top: 10%;
    margin-left: 4%;
    margin-bottom: 3%;
    font-weight: 800; /* Augmentation du poids de la police */
  }
}
@media (min-width: 1240px) and (max-width: 1300px) {
  .features {
    font-size: 35px; /* Adjust font size for small desktops */
    margin-top: 9%;
    margin-left: 5%;
    margin-bottom: 4%;
  }
}
@media (min-width: 1300px) {
  .features {
    font-size: 40px; /* Adjust font size for small desktops */
    margin-top: 5%;
    margin-left: 5%;
    margin-bottom: 4%;
  }
}

.icon_search {
  width: 24px; /* Default width */
  height: 24px; /* Default height */
  transition: width 0.3s ease, height 0.3s ease; /* Smooth transition for resizing */
}

/* Responsive styles */

/* For screens 300px to 480px */
@media (max-width: 480px) {
  .icon_search {
    width: 12px; /* Smaller size for mobile */
    height: 14px;
  }
}

/* For screens 481px to 768px */
@media (min-width: 481px) and (max-width: 768px) {
  .icon_search {
    width: 16px; /* Medium size for tablets */
    height: 19px;
  }
}

/* For screens 769px to 1024px */
@media (min-width: 769px) and (max-width: 1024px) {
  .icon_search {
    width: 24px; /* Default size for small desktops */
    height: 24px;
  }
}

/* For screens 1025px to 1500px */
@media (min-width: 1025px) and (max-width: 1500px) {
  .icon_search {
    width: 28px; /* Larger size for larger screens */
    height: 28px;
  }
}

/* For screens above 1500px */
@media (min-width: 1501px) {
  .icon_search {
    width: 32px; /* Even larger size for very large screens */
    height: 32px;
  }
}

.explore_container {
  width: 100%;
  max-width: 875px;
  height: 60px;
  border-radius: 10px;
  box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  align-items: center;
  margin-top: 25px;
  border: 1px solid;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px; /* Added padding for better content spacing */
}
@media (max-width: 450px) {
  .explore_container {
    width: 80%; /* Use most of the screen width */
    height: 31px; /* Let the height adjust to content */

    padding: 0px; /* Adjust padding for smaller screens */
    margin-top: -6px; /* Adjust top margin */
    border: 1px solid;
    margin-left: 5%; /* Center the container with auto margin */
    margin-right: 5%; /* Center the container with auto margin */
    margin-bottom: 3%;
  }
}

/* For small screens (phones) */
@media (min-width: 450px) and (max-width: 577px) {
  .explore_container {
    width: 64%; /* Use most of the screen width */
    height: 33px; /* Let the height adjust to content */

    padding: 0px; /* Adjust padding for smaller screens */
    margin-top: -13px; /* Adjust top margin */
    border: 1px solid;
    margin-left: 5%; /* Center the container with auto margin */
    margin-right: 5%; /* Center the container with auto margin */
    margin-bottom: 3%;
  }
}
@media (min-width: 577px) and (max-width: 670px) {
  .explore_container {
    width: 72%; /* Adjust the width for tablets */
    height: auto; /* Let the height adjust to content */
    padding: 4px; /* Adjust padding for tablets */
    margin-top: -7px; /* Adjust top margin */
    margin-left: 10%; /* Center the container with auto margin */
    margin-right: 10%; /* Center the container with auto margin */
    margin-bottom: 1%;
  }
}

/* For medium screens (tablets) */
@media (min-width: 670px) and (max-width: 769px) {
  .explore_container {
    width: 64%; /* Adjust the width for tablets */
    height: auto; /* Let the height adjust to content */
    padding: 4px; /* Adjust padding for tablets */
    margin-top: -16px; /* Adjust top margin */
    margin-left: 10%; /* Center the container with auto margin */
    margin-right: 10%; /* Center the container with auto margin */
    margin-bottom: 1%;
  }
}

/* For large screens (small desktops) */
@media (min-width: 769px) and (max-width: 1240px) {
  .explore_container {
    width: 70%; /* Adjust the width for small desktops */

    padding: 15px; /* Adjust padding for small desktops */
    margin-top: -25px; /* Adjust top margin */
    margin-left: 2%; /* Center the container with auto margin */
    margin-right: 0; /* Center the container with auto margin */
    margin-bottom: 1%;
  }
}

/* For extra large screens (large desktops) */
@media (min-width: 1240px) and (max-width: 1260px) {
  .explore_container {
    width: 42%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: 25px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0 20px; /* Added padding for better content spacing */
  }
}
@media (min-width: 1260px) and (max-width: 1300px) {
  .explore_container {
    width: 43.5%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -16px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0 20px; /* Added padding for better content spacing */
  }
}
@media (min-width: 1300px) and (max-width: 1330px) {
  .explore_container {
    width: 39%;
    max-width: 877px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -15px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0; /* Added padding for better content spacing */
  }
}
@media (min-width: 1330px) and (max-width: 1400px) {
  .explore_container {
    width: 40.5%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -15px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0; /* Added padding for better content spacing */
  }
}
@media (min-width: 1400px) and (max-width: 1450px) {
  .explore_container {
    width: 43%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -15px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0; /* Added padding for better content spacing */
  }
}
@media (min-width: 1450px) and (max-width: 1500px) {
  .explore_container {
    width: 44%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -15px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0; /* Added padding for better content spacing */
  }
}
@media (min-width: 1500px) and (max-width: 1730px) {
  .explore_container {
    width: 44%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -15px;
    border: 1px solid;
    margin-left: 3%;
    margin-right: auto;
    padding: 0; /* Added padding for better content spacing */
  }
  .textsearchtitle {
    color: white;
    font-size: 41px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
  }
  .textsearch {
    font-size: 13px;
    padding-top: 0;
    padding-bottom: 6px;
  }
  .textsearch2 {
    color: white;
    font-size: 13px;
    padding-top: 2%;
    font-family: "Lato";
  }
}

@media (min-width: 1730px) {
  .explore_container {
    width: 46%;
    max-width: 875px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 4px 4px 10.3px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    align-items: center;
    margin-top: -15px;
    border: 1px solid;
    /* margin-left: 3%; */
    margin-right: auto;
    padding: 0; /* Added padding for better content spacing */
  }
}

.explore_btn {
  background-color: transparent;
  border: none;
  color: black;
  font-size: 20px;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
  width: 20%;
  cursor: pointer;
  position: relative;
}
/* Media Queries for Responsive Design */
@media (max-width: 576px) {
  .explore_btn {
    font-size: 7px; /* Adjust font size for small screens */
    width: 50%; /* Adjust width for small screens */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .explore_btn {
    font-size: 13px; /* Adjust font size for tablets */
    width: 30%; /* Adjust width for tablets */
  }
}

@media (min-width: 769px) and (max-width: 1240px) {
  .explore_btn {
    font-size: 18px; /* Keep original font size for small desktops */
    width: 30%; /* Adjust width for small desktops */
  }
}

@media (min-width: 1240px) {
  .explore_btn {
    font-size: 20px;
    font-weight: 700;
  }
}

.motivationImg {
  display: flex;
  flex-direction: row;
  background-color: #3e467800;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 2%;
  gap: 5px;
}

/* For small screens (phones) */
@media (max-width: 576px) {
  .motivationImg {
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Reduce gap */
    /* padding-left: 5%;
           padding-right: 5%; */
  }
}

/* For medium screens (tablets) */
@media (min-width: 577px) and (max-width: 768px) {
  .motivationImg {
    flex-direction: row; /* Row direction */
    gap: 15px; /* Adjust gap for tablets */
    /* padding-left: 5%;
           padding-right: 5%; */
  }
}

/* For large screens (small desktops) */
@media (min-width: 769px) and (max-width: 1240px) {
  .motivationImg {
    flex-direction: row; /* Row direction */
    gap: 20px; /* Adjust gap for small desktops */
    /* padding-left: 5%;
           padding-right: 5%; */
  }
}

/* For extra large screens (large desktops) */
@media (min-width: 1240px) and (max-width: 1500px) {
  .motivationImg {
    justify-content: left;
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .motivationImg {
    justify-content: left;
    width: 100%;
    flex-wrap: wrap;
  }
}

.topTrainingElements {
  display: flex;
  flex-direction: row;
  /* padding: 1rem; */
  /* padding-left: 30px;
       padding-right: 30px; */

  /* width: 100%; */
  /* margin-bottom: 1em; */
  /* margin-left: -50px; */
  align-items: center;
  justify-content: center;
}

.carousel {
  /* padding-left:10px;
       padding-right: 10px; */
  font-family: "Lato";
}

.inner_carousel {
  width: clamp(120px, 23vw, 180px); /* Adjusts between 120px and 180px */
  height: clamp(100px, 20vw, 230px); /* Adjusts between 100px and 230px */
  border-radius: 0px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
@media (max-width: 320px) {
  .inner_carousel {
    width: clamp(104px, 20vw, 191px);
    height: clamp(162px, 34vw, 217px);
  }
}
@media (min-width: 320px) and (max-width: 340px) {
  .inner_carousel {
    width: clamp(95px, 20vw, 191px); /* Adjusts between 100px and 150px */
    height: clamp(145px, 34vw, 217px); /* Adjusts between 150px and 200px */
  }
}
@media (min-width: 340px) and (max-width: 370px) {
  .inner_carousel {
    width: clamp(129px, 23vw, 191px); /* Adjusts between 100px and 150px */
    height: clamp(187px, 36vw, 217px); /* Adjusts between 150px and 200px */
  }
}
@media (min-width: 370px) and (max-width: 400px) {
  .inner_carousel {
    width: clamp(129px, 23vw, 191px); /* Adjusts between 100px and 150px */
    height: clamp(187px, 36vw, 217px); /* Adjusts between 150px and 200px */
  }
}
@media (min-width: 400px) and (max-width: 420px) {
  .inner_carousel {
    width: clamp(112px, 20vw, 191px); /* Adjusts between 100px and 150px */
    height: clamp(155px, 34vw, 217px); /* Adjusts between 150px and 200px */
  }
}
@media (min-width: 420px) and (max-width: 460px) {
  .inner_carousel {
    width: clamp(129px, 23vw, 191px); /* Adjusts between 100px and 150px */
    height: clamp(187px, 36vw, 217px); /* Adjusts between 150px and 200px */
  }
}

/* For small screens (phones) */
@media (min-width: 460px) and (max-width: 576px) {
  .inner_carousel {
    width: clamp(123px, 29vw, 191px);
    height: clamp(150px, 41vw, 217px); /* Adjusts between 150px and 200px */
  }
}

/* For medium screens (tablets) */
@media (min-width: 577px) and (max-width: 819px) {
  .inner_carousel {
    width: clamp(120px, 29vw, 219px);
    height: clamp(170px, 38vw, 297px);
  }
}

/* For large screens (small desktops) */
@media (min-width: 819px) and (max-width: 1024px) {
  .inner_carousel {
    width: clamp(141px, 25vw, 235px);
    height: clamp(180px, 32vw, 316px);
  }
}

/* For extra large screens (large desktops) */
@media (min-width: 1025px) and (max-width: 1240px) {
  .inner_carousel {
    width: clamp(160px, 23vw, 325px);
    height: clamp(200px, 36vw, 372px);
  }
}
@media (min-width: 1240px) and (max-width: 1380px) {
  .inner_carousel {
    width: clamp(160px, 22vw, 324px); /* Adjusts between 160px and 220px */
    height: clamp(200px, 36vw, 400px); /* Adjusts between 200px and 270px */
  }
}
@media (min-width: 1380px) {
  .inner_carousel {
    width: clamp(160px, 40vw, 300px); /* Adjusts between 160px and 220px */
    height: clamp(200px, 30vw, 410px); /* Adjusts between 200px and 270px */
  }
}

.inner_carousel:last-child {
  margin-right: 0; /* Remove margin from the last item */
}
.image {
  width: 100%; /* Exemple de largeur */
  height: 100px;
  overflow: hidden;
  margin-bottom: 10%;
}

/* Pour les petits appareils (téléphones) */
@media (max-width: 630px) {
  .image {
    height: 56%; /* Ajuster la hauteur pour les petits écrans */
  }
}
@media (min-width: 630px) and (max-width: 711px) {
  .image {
    height: 56%;
  }
}
/* Pour les tablettes et petits appareils */
@media (min-width: 711px) and (max-width: 815px) {
  .image {
    height: 56%;
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 815px) and (max-width: 921px) {
  .image {
    height: 55%;
  }
}

/* Pour les petits desktops */
@media (min-width: 921px) and (max-width: 1122px) {
  .image {
    height: 56%;
  }
}

/* Pour les moyens desktops */
@media (min-width: 1122px) and (max-width: 1200px) {
  .image {
    height: 56%;
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) and (max-width: 1300px) {
  .image {
    height: 61%;
  }
}
@media (min-width: 1301px) and (max-width: 1390px) {
  .image {
    height: 56%;
  }
}
@media (min-width: 1390px) and (max-width: 3000px) {
  .image {
    height: 56%;
  }
}
@media (min-width: 3000px) {
  .image {
    height: 56%;
  }
}

.imagefeatures {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  border-radius: 0px 10px 0px 10px;
}
.arrows {
  width: 180%;
}

/* Pour les petits appareils (téléphones) */
@media (max-width: 576px) {
  .arrows {
    width: 2rem; /* Ajuster la largeur pour les petits écrans */
  }
}

/* Pour les tablettes et petits appareils */
@media (min-width: 577px) and (max-width: 768px) {
  .arrows {
    width: 2.5rem; /* Ajuster la largeur pour les tablettes */
  }
}

/* Pour les petits desktops */
@media (min-width: 769px) and (max-width: 992px) {
  .arrows {
    width: 2rem; /* Ajuster la largeur pour les petits desktops */
  }
}

/* Pour les moyens desktops */
@media (min-width: 993px) and (max-width: 1200px) {
  .arrows {
    width: 2rem; /* Ajuster la largeur pour les moyens desktops */
  }
}

/* Pour les grands desktops */
@media (min-width: 1201px) {
  .arrows {
    width: 2rem; /* Largeur pour les grands desktops */
  }
}

.niveau {
  color: gray;
  margin-left: 15px;
  margin-top: 6%;
  font-weight: 600;
}
.descr {
  margin-left: 15px;
  font-weight: 700;
  color: black;
  margin-top: 4%;
}
.notes {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-top: 4%;
  margin-left: 15px;
}
.numnote {
  font-size: 12px;
  color: gray;
  margin-left: 16%;
  margin-top: 1%;
}
.star {
  width: 100%;
  height: 95%;
}
.markes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 4%;
  margin-top: 4%;
}
.ravi {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
}
.numnote2 {
  font-size: 12px;
  color: gray;
  margin-left: 16%;
  margin-top: 1%;
}
.containernote {
  margin-left: -4%;
}

@media (max-width: 480px) {
  .niveau,
  .descr,
  .categorietype {
    margin-left: 10px;
    margin-top: 4%;
    font-size: 6px; /* Adjust font size for small screens */
  }
  .ravi {
    margin-top: 3%; /* Adjust margin for small screens */
  }
  .numnote {
    font-size: 6px;
  }
  .numnote2 {
    font-size: 6px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .niveau,
  .descr,
  .categorietype {
    margin-left: 21px;
    margin-top: 5%;
    font-size: 7px;
  }
  .ravi {
    margin-block: 3%;
    margin-inline: 5%;
  }
  .numnote {
    font-size: 7px;
  }
  .numnote2 {
    font-size: 7px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .niveau,
  .descr,
  .categorietype {
    margin-left: 13px;
    margin-top: 5.5%;
    font-size: 9px;
  }
  .ravi {
    margin-block: 2.5%; /* Adjust margin for large screens */
  }
  .numnote {
    font-size: 9px;
  }
  .numnote2 {
    font-size: 9px;
  }
}

@media (min-width: 1025px) and (max-width: 1320px) {
  .niveau,
  .descr,
  .ravi {
    margin-left: 15px;
    margin-top: 6%;
    font-size: 17px; /* Default size for larger screens */
  }
  .ravi {
    margin-top: 2%; /* Default margin for larger screens */
  }
  .numnote {
    font-size: 17px;
  }
  .numnote2 {
    font-size: 17px;
  }
}
.plus {
  width: 100%;
}
.pluses {
  display: flex;
  justify-content: flex-end;
  margin-left: 90%;
}
