.container {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.questionText {
  max-width: 600px;
}

.actions {
  display: flex;
  gap: 30px;
}

.actions svg:first-child {
  color: var(--secondary-color);
  cursor: pointer;
}

.actions svg:last-child {
  color: var(--primary-color);
  cursor: pointer;
  margin-left: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttons {
  display: flex;
  gap: 10px;
}

.buttons button {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  width: 150px;
}
