.background_container {
  background: url("../../../assets/background-image.png") no-repeat center
    center;
  background-size: cover;
  height: 100dvh;
  position: relative;
}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title {
  text-transform: capitalize;
  font-size: var(--font-size-large);
  font-weight: 800;
  width: fit-content;
  color: var(--text-color);
  position: relative;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.group {
  display: flex;
  color: white;
  margin-top: 5rem;
}

.group > div {
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
}

.content .details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.group .content .description {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.8rem;
  text-transform: lowercase;
}

.buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.buttons button {
  border-radius: 6px;
}

.buttons button:first-child {
  background-color: white;
  color: var(--secondary-color);
}

.buttons button:last-child {
  background-color: var(--primary-color);
}

@media (max-width: 992px) {
  .image {
    display: none;
  }
}
