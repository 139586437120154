.btnstyle {
  border: none;
  min-width: 5rem;
  border-bottom: 0.2px gray;
  color: #9c9b9b;
  background-color: transparent;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 900;
  font-size: 1.5rem;
  cursor: pointer;
}

.btnselectedstyle {
  border: none;
  background-color: #eaeaea;
  border-bottom: 5px solid #cd6214;
  color: black;
}

.indicator {
  font-size: 0.9rem;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 1px solid #9ca0b9;
}

.btnselectedstyle .indicator {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

@media (max-width: 800px) {
  .btnstyle {
    padding: 10px 15px;
    width: 100%;
  }
}
