.allContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3e4678;
  margin-bottom: 3rem;
  margin-top: 7rem;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 25px;
  padding: 7%;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-left: 0%;
}

.mainContainer {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}

.imageWrapper {
  width: 8%;
  position: absolute;
  top: 0%;
  right: 0%;
}

.finalWrapper {
  width: 10%;
  display: flex;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  color: #ffffff;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 0%;
  text-align: center;
  font-family: "Lato";
  margin-top: 0%;
  width: 100%;
}

.paragraph {
  color: #e0e0e0;
  font-size: 15px;
  text-align: center;
  line-height: 1.2;
  font-family: "Lato";
  margin-top: 5%;
  margin-bottom: 5%;
  width: 70%;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  position: relative;
}

.moreButton {
  width: 5.87rem;
  height: 2.5rem;
  padding: 0.7rem 0.4rem;
  background-color: white;
  color: #1c4b82;
  font-weight: bold;
  text-align: center;
  font-family: "Lato";
  font-size: 13px;
  line-height: 1;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.moreButton:hover {
  background-color: #cd6214;
  color: #ffffff;
}
@media (max-width: 430px) {
  .allContainer {
    margin: 2rem 0px;
    padding: 0rem;
    height: 50%;
    margin-left: 0%;
  }
  .title {
    font-size: 10px;
    margin-top: -1%;
  }
  .image,
  .image1 {
    width: 100%;
  }
  .paragraph {
    font-size: 6px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 60%;
  }
  .moreButton {
    padding: 0.5rem 0.4rem;
    font-size: 6px;
    margin-bottom: 20%;
    width: 6rem;
  }
}
@media (max-width: 550px) and (min-width: 430px) {
  .allContainer {
    margin: 3rem 0px;
    padding: 0rem;
    height: 50%;
    margin-left: 0%;
  }
  .title {
    font-size: 15px;
    margin-top: -2%;
  }
  .image,
  .image1 {
    width: 100%;
  }

  .paragraph {
    font-size: 7px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 60%;
  }
  .moreButton {
    padding: 0.5rem 0.4rem;
    font-size: 7px;
    margin-bottom: 20%;
    width: 6rem;
    height: 2rem;
  }
}

@media (min-width: 550px) and (max-width: 600px) {
  .allContainer {
    margin: 3rem 0px;
    padding: 3rem;
    height: auto;
  }

  .title {
    font-size: 19px;
    margin-top: -5%;
  }
  .moreButton {
    font-size: 8%;
  }

  .paragraph {
    font-size: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 65%;
  }

  .moreButton {
    padding: 0.7rem 1.2rem;
    font-size: 7px;
    margin-bottom: -3%;
    margin-top: 0px;
  }

  .mainContainer {
    width: 100%;
    margin-top: 1rem;
  }

  .image,
  .image1 {
    width: 100%;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .title {
    font-size: 23px;
    margin-top: -10%;
  }

  .paragraph {
    font-size: 10px;
    margin-top: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  .moreButton {
    padding: 0.6rem 0.5rem;
    font-size: 9px;
  }

  .mainContainer {
    width: 75%;
  }

  .image,
  .image1 {
    width: 100%;
  }
  .allContainer {
    margin-top: 5rem;
    margin-bottom: 5%;
  }
}

@media (min-width: 700px) and (max-width: 800px) {
  .title {
    font-size: 25px;
    margin-bottom: -10%;
    margin-top: -7%;
  }
  .allContainer {
    margin-left: 1%;
    margin-top: 4rem;
    margin-bottom: 8%;
  }

  .paragraph {
    font-size: 12px;
    margin-top: 5rem;
    width: 80%;
    margin-bottom: 2rem;
  }

  .moreButton {
    padding: 0.2rem 0.5rem;
    font-size: 10px;
    width: 6.5rem;
  }

  .mainContainer {
    width: 80%;
  }

  .image {
    width: 100%;
  }

  .image1 {
    width: 120%;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
  .title {
    font-size: 30px;
    margin-top: -5%;
  }
  .allContainer {
    margin-top: 5rem;
    padding: 50px;
    height: auto;
    margin-left: 0%;
  }

  .image,
  .image1 {
    width: 100%;
  }

  .paragraph {
    font-size: 15px;
    /* margin-top: 2rem; */
  }
}
@media (max_width: 1025px) and (min-width: 992px) {
  .allContainer {
    margin: 3rem 0px;
    padding: 3rem;
    height: auto;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .title {
    font-size: 35px;
    width: 200%;
    margin-top: -10%;
  }

  .paragraph {
    font-size: 15px;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mainContainer {
    width: 60%;
  }

  .image,
  .image1 {
    width: 100%;
  }
}

@media (min-width: 1201px) {
  .title {
    font-size: 35px;
    width: 200%;
    margin-top: -10%;
  }

  .paragraph {
    font-size: 20px;
    margin-top: 8%;
    margin-bottom: 8%;
    width: 100%;
  }

  .moreButton {
    padding: 0.2rem 0.2rem;
    font-size: 12px;
    line-height: 1;
    margin-bottom: -5%;
  }

  .mainContainer {
    width: 50%;
  }

  .imageWrapper {
    width: 8%;
  }

  .finalWrapper {
    width: 10%;
  }
}
@media (max-width: 350px) {
  .title {
    font-size: 8px;
    width: 100%;
    margin-top: -3%;
  }
  .moreButton {
    padding: 0.5rem 0.4rem;
    font-size: 6px;
    margin-bottom: 12%;
  }
}
