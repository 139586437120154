.logo {
  position: relative;
  margin: 3rem auto 0;
  width: fit-content;
}

.logo img {
  width: 240px;
}

.logo p {
  font-size: 0.7rem;
  font-weight: 300;
  line-height: 1.8rem;
  color: var(--text-color);
  position: absolute;
  bottom: -4px;
  font-weight: 400;
  right: 0;
}

@media (max-width: 992px) {
  .logo {
    margin-top: 2rem;
  }
  .logo img {
    width: 150px;
  }
  .logo p {
    font-size: 0.5rem;
  }
}
