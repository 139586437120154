@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  font-family: "Lato", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --breakpoint-xs: 0px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --primary-color: #cd6214;
  --secondary-color: #3e4678;
  --shadow-color: rgba(0, 0, 0, 26%);
}

body {
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  width: 100%;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.203);
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.164);
  border-radius: 5px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #00000093;
}

::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px rgba(117, 117, 117, 0.541);
}

.wizard-icon-circle {
  width: 80px !important;
  height: 80px !important;
}
.wizard-progress-with-circle {
  top: 44px !important;
}

.wizard-card-footer {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.wizard-navigation {
  min-height: 65vh !important;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.appWrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 12px;
  }

  @media (max-width: 480px) {
    padding: 0 8px;
  }
}

/* Container for the header background image */
/* .background_container {
  background: url("./component/assets/background-image.png") no-repeat center
    center;
  background-size: cover;
  height: 31vh;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;
} */
