.container {
  padding: 15px 20px;
}

.input {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: fit-content;
  align-items: center;
}

.input button {
  align-items: center;
  width: 200px;
  padding: 10px 20px;
  height: 100%;
}

.input button span {
  margin-bottom: 4px;
}

.content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  gap: 10px;
  padding: 10px;
  border-radius: 6px;
  max-width: 300px;
  min-width: 250px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
}

.box img {
  width: 100px;
  max-height: 100px;
  object-fit: contain;
}

.details {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
  width: 100%;
}

.details .group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-weight: 700;
}

.details .group .status {
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 12px;
  color: white;
}

.questionNumber {
  font-size: 12px;
  color: #a0a0a0;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

/* edit */
.edit {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  background-color: black;
  display: grid;
  place-items: center;
  cursor: pointer;
}
