.modal {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 4rem;
  max-width: 900px;
  width: 90%;
  text-align: center;
  position: absolute;
  opacity: 0;
  height: 78dvh;
  visibility: hidden;
  top: 200px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  border-radius: 30px;
  overflow-y: auto;
  z-index: 999;

  @media (max-width: 768px) {
    top: 150px;
  }
}

.modal.active {
  opacity: 1;
  visibility: visible;
}

.modal .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
}

.modal .row:last-of-type {
  margin-bottom: 4rem;
}

.modal > hr,
.buttons hr {
  background-color: #d6d6d6;
  border: none;
  border-radius: 50px;
}

.modal > hr {
  width: 100%;
  height: 3px;
  margin: 5px 0 0;
}

.buttons {
  display: flex;
  gap: 0;
}

.buttons hr {
  width: 3px;
  height: 50px;
  background-color: var(--primary-color);
  margin: 0;
}

.modal .buttons button {
  padding: 0 15px;
  color: #9f9f9f;
  font-weight: 800;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  width: max-content;
  text-transform: uppercase;
}

.buttons button.active {
  color: var(--primary-color);
}

.modal .buttons button span {
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  display: grid;
  place-items: center;
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.8rem;
  margin-left: 10px;
}

.arrow {
  color: #fff;
  width: max-content;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 0;
  transition: transform 0.4s ease;
}

.arrow > svg {
  border-radius: 50%;
  background-color: #d6d6d6;
}

.faqItem {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.faqItem .box {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.box .answered {
  background-color: #e8e8e8;
  border-radius: 20px;
  font-size: 13px;
  padding: 0 15px;
  display: grid;
  place-items: center;
  margin-left: 20px;
  height: 20px;
  color: #808080;
}

.box .answered.active {
  background-color: rgba(205, 98, 20, 0.3);
  color: var(--primary-color);
}

.question {
  padding: 16px 0px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  position: relative;
}

.faqItem .active > svg {
  transform: rotate(180deg);
}

.answer {
  margin-left: 55px;
  text-align: start;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  opacity: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answer > p:first-child {
  font-weight: 600;
}

.answer > p:last-child {
  font-weight: 800;
}

.answer.open {
  opacity: 1;
}

.group {
  display: fl;
}
