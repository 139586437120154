.aboutContainer {
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  width: 100%;
  padding-bottom: 5%;
}

.aboutContainercollab {
  display: flex;
  flex-direction: column;

  margin-bottom: 50px;
  /* padding-bottom: 5%; */
  background-color: white;
  border-top: 1px solid #58434331; /* Top border */
  border-bottom: 1px solid #58434331; /* Bottom border */
  border-left: none; /* No left border */
  border-right: none; /* No right border */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.465); /* Inner shadow */
}
.dernierSection {
  /* padding: 0.5rem; */
  display: flex;
  flex-direction: row;
  /* padding: 50px; */
  /* justify-content:space-between; */
  /* margin-left: -20px; */
  background-color: orange;
  padding-left: 6.5rem;
  padding-right: 6.5rem;

  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.description {
  font-size: 20px;
  margin-top: 1rem;
  color: black;
  line-height: 40px;
  font-weight: bold;
  font-family: "Lato";

  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: row;
}

.descriptionpar {
  margin-top: 50px;
  /* margin-right: 500px;  */
}
.imageAboutUs {
  align-self: center;
}

.imageWrapper {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex-direction: row;

  /* background-color: #cd6214; */
}

.aboutTitle {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 50px;
}
.underline {
  width: 10%;
  background-color: #cd6214;
  padding-top: 5px;
  border-radius: 100px;
  margin-top: 20px;
}

.imageConsultant {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 30px;
  color: #cd6214;
}

.subtitle {
  font-size: 20px;
  /* margin-top: 0.5rem; */
  color: #1c4b82;
  text-transform: capitalize;
}

.collaborateTitle {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 50px;
}

.ourPartners {
  width: 100%;
  height: 30%;
  display: inline-block;
}
.ourPartners_container {
  overflow-x: hidden;
  display: flex;
  white-space: nowrap; /* Permet d'éviter les retours à la ligne */
  width: 100%; /* Ajustez selon vos besoins */
}
.ourPartners_container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.ourPartners div {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Media Queries for Responsive Design */
@media (max-width: 470px) {
  .ourPartners div {
    margin-left: 0; /* Adjust margin if needed */
  }
}

@media (min-width: 471px) and (max-width: 576px) {
  .ourPartners div {
    margin-left: 0; /* Adjust margin if needed */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .ourPartners div {
    flex-direction: row; /* Arrange items in a row for tablets */
    margin-left: auto; /* Restore original margin if needed */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .ourPartners div {
    flex-direction: row; /* Arrange items in a row for small desktops */
    margin-left: auto; /* Restore original margin if needed */
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .ourPartners div {
    flex-direction: row; /* Keep original flex direction for larger desktops */
    margin-left: auto; /* Restore original margin if needed */
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .ourPartners div {
    flex-direction: row; /* Keep original flex direction for even larger desktops */
    margin-left: auto; /* Restore original margin if needed */
  }
}

@media (min-width: 1401px) and (max-width: 1600px) {
  .ourPartners div {
    flex-direction: row; /* Keep original flex direction for very large desktops */
    margin-left: auto; /* Restore original margin if needed */
  }
}

@media (min-width: 1601px) and (max-width: 2000px) {
  .ourPartners div {
    flex-direction: row; /* Keep original flex direction for ultra-large desktops */
    margin-left: auto; /* Restore original margin if needed */
  }
}

@media (min-width: 2001px) {
  .ourPartners div {
    flex-direction: row; /* Keep original flex direction for ultra-large desktops */
    margin-left: auto; /* Restore original margin if needed */
  }
}

.ourPartners div img {
  max-width: 100px;
  margin: 20px;
  filter: grayscale(1);
}

.ourPartners div img:hover {
  filter: grayscale(0);
}

/* Media queries for responsiveness */

/* For large desktops and laptops */
@media (min-width: 1200px) {
  .ourPartners {
    height: 25%;
  }
  .ourPartners div img {
    max-width: 150px;
  }
}
@media (min-width: 1200px) and (max-width: 1401px) {
  .ourPartners {
    height: 25%;
  }
  .ourPartners div img {
    max-width: 150px;
  }
}
/* For very large desktops */
@media (min-width: 1401px) and (max-width: 1600px) {
  .ourPartners {
    height: 25%;
  }
  .ourPartners div img {
    max-width: 170px;
  }
}

/* For ultra-large desktops */
@media (min-width: 1601px) and (max-width: 2000px) {
  .ourPartners {
    height: 30%;
  }
  .ourPartners div img {
    max-width: 180px;
  }
}

/* For ultra-large desktops over 2000px */
@media (min-width: 2001px) {
  .ourPartners {
    height: 25%;
  }
  .ourPartners div img {
    max-width: 200px;
  }
}

/* For medium-sized devices (tablets and small laptops) */
@media (min-width: 768px) and (max-width: 1199px) {
  .ourPartners {
    height: 30%;
  }
  .ourPartners div img {
    max-width: 120px;
  }
}

/* For small devices (mobile phones) */
@media (max-width: 767px) {
  .ourPartners {
    height: 40%;
  }
  .ourPartners div img {
    max-width: 70px;
  }
}

.readButton {
  width: 6.87rem;
  height: 2.5rem;
  box-shadow: #1c4b82;
  background-color: #1c4b82;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  font-family: "Nunito";
  border: none;
  border-radius: 6px;
  cursor: pointer;

  /* margin-bottom: 30px; */
}

.readButton:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
}

.consultantSection {
  /* padding: 7rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  padding-left: 6.5rem;
  padding-right: 6.5rem;
  padding-bottom: 5%;
  /* background-color: aqua; */
  /* margin-left: -20px; */
}

.allParaghraphe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 70px; */
  /* background-color: #cd6214; */
  padding-left: 90px;
  padding-right: 90px;
}
.descriptionParagh {
  text-align: center;
}

.consultantsTitle {
  /* margin-bottom: 20px; */
  font-size: 36px;
  font-weight: bold;
  /* width: 29rem; */
  /* background-color: #cd6214; */
}

.button {
  display: flex;
  justify-content: center;
}

.applyButton {
  width: 6.87rem;
  height: 2.5rem;
  box-shadow: #1c4b82;
  background-color: #1c4b82;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  font-family: "Nunito";
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
}

.applyButton:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
}

.subtitle {
  font-size: 25px;
  /* margin-top: 0.5rem; */
  color: #1c4b82;
  text-transform: uppercase;
}

/*media*/

@media (max-width: 1300px) {
  .descriptionpar {
    margin-right: 0px;
  }
  .imageAboutUs {
    /* width: 100%;
      height:100%; */
    background-color: rgba(17, 243, 36, 0);
    width: 100%;

    /* margin-right: -50px; */
    /* margin-top: 40px;
      padding-right: 80px; */
  }
  .underline {
    width: 10%;
    background-color: #cd6214;
    padding-top: 3px;
    border-radius: 100px;
    margin-top: 1px;
  }
  .description {
    font-size: 15px;
    margin-top: 1rem;
    color: black;
    line-height: 25px;
    font-weight: bold;
    font-family: "Lato";
  }
  .subtitle {
    font-size: 25px;
    /* margin-top: 0.5rem; */
    color: #1c4b82;
  }

  .readButton {
    width: 6.87rem;
    height: 2.5rem;
    box-shadow: #1c4b82;
    background-color: #1c4b82;
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    font-family: "Nunito";
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;
    /* margin-bottom: 30px; */
  }

  .readButton:hover {
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .card {
    width: 120px;
    height: 120px;
  }
  .cardsContainer {
    gap: 20px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .aboutTitle {
    margin-bottom: 0;
    margin-bottom: 0rem;
    /* padding-left: 30px;
    padding-right: 30px; */
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: bold;
  }
}

.title {
  font-size: 15px;
  color: #cd6214;
  font-weight: bolder;
}

.description {
  font-size: 5px;
  margin-top: 1rem;
  color: #2c2c2c;
  line-height: 20px;
  font-weight: 700;
  font-family: "Lato";
  background-color: rgba(127, 255, 212, 0);
  margin-right: 50px;
}
.aboutContainer {
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  width: auto;
  background-color: rgba(0, 0, 255, 0);
  padding: 5px;
}
.title {
  font-size: 32px;
}

.dernierSection {
  /* padding: 0.5rem; */
  display: flex;
  flex-direction: row;
  /* padding: 50px; */
  /* justify-content:space-between; */
  /* margin-left: -20px; */
  background-color: orange;
  padding: 0px;

  margin-top: 50px;
  align-items: center;
  justify-content: center;
}
* {
  font-size: 12px; /* Base font size for all elements */

  /* Media Queries for Responsive Design */
}
@media (max-width: 370px) {
  * {
    font-size: 6px; /* Adjust font size for extra small screens */
  }
}
@media (max-width: 470px) and (min-width: 371px) {
  * {
    font-size: 8px; /* Adjust font size for extra small screens */
  }
}

@media (min-width: 471px) and (max-width: 576px) {
  * {
    font-size: 12px; /* Adjust font size for small screens */
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  * {
    font-size: 13px; /* Adjust font size for tablets */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  * {
    font-size: 14px; /* Adjust font size for small desktops */
  }
}

@media (min-width: 1025px) {
  * {
    font-size: 16px; /* Adjust font size for large desktops */
  }
}

.allParaghraphe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left: 70px; */
  /* background-color: #cd6214; */
}

.collaborateTitle {
  margin-bottom: 0;
  font-size: 12px;
  font-weight: bold;
  padding-bottom: 5px;
}

.consultantsTitle {
  /* margin-bottom: 20px; */
  font-size: 15px;
  font-weight: bold;
  align-self: center;
  /* width: 29rem; */
  /* background-color: #cd6214; */
}
.title {
  font-size: 14px;
}
.imageConsultant {
  width: 100%;
  margin-top: 20px; /* height: 28.12rem; */
  /* margin-left: -80px; */
  /* margin-top: -80px; */
}
.allParaghraphe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.dernierSection {
  /* padding: 0.5rem; */
  display: flex;
  flex-direction: row;
  /* padding: 50px; */
  /* justify-content:space-between; */
  /* margin-left: -20px; */
  background-color: rgba(255, 166, 0, 0);
  padding: 0px;

  margin-top: 50px;
  align-items: center;
  justify-content: center;
}
.collabSection {
  overflow: hidden;
}
.aboutContainercollab {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-top: -73px; */
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 5%;
}
@media (max-width: 340px) {
  .aboutContainercollab {
    padding-left: 10px; /* Reduce padding for smaller screens */
    padding-right: 10px; /* Reduce padding for smaller screens */
    margin-bottom: 7%;
  }
}

/* For screens up to 300px */
@media (min-width: 340px) and (max-width: 420px) {
  .aboutContainercollab {
    padding-left: 10px; /* Reduce padding for smaller screens */
    padding-right: 10px; /* Reduce padding for smaller screens */
    margin-bottom: 7%;
  }
}

/* For screens between 301px and 400px */
@media (min-width: 420px) and (max-width: 485px) {
  .aboutContainercollab {
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 7%;
  }
}

/* For screens between 401px and 500px */
@media (min-width: 485px) and (max-width: 500px) {
  .aboutContainercollab {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 8%;
  }
}

/* For screens between 501px and 600px */
@media (min-width: 501px) and (max-width: 600px) {
  .aboutContainercollab {
    padding-left: 18px;
    padding-right: 18px;
    margin-bottom: 7%;
  }
}

/* For screens between 601px and 700px */
@media (min-width: 601px) and (max-width: 700px) {
  .aboutContainercollab {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* For screens between 701px and 800px */
@media (min-width: 701px) and (max-width: 769px) {
  .aboutContainercollab {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5%;
  }
}

.consultantSection {
  /* padding: 7rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  padding-left: 20px;
  padding-right: 20px;

  /* background-color: aqua; */
  /* margin-left: -20px; */
}
.descriptionpar {
  margin-top: 0px;
  margin-right: 0px;
}

.imageAboutUs {
  position: absolute;
  width: 40%;
  background-color: rgba(17, 243, 36, 0);
  z-index: -3;
}

.imageWrapper {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex-direction: row;
  background-color: blue;
  /* margin-right: -80px; */
  width: 100%;
  z-index: -1000;
  /* background-color: #cd6214; */
}

.container {
  padding-left: 0px;
  padding-right: 50px;
}

@media (max-width: 400px) {
  .textsearchtitle {
    color: rgb(219, 4, 86);
    font-size: 20px;
    font-weight: 500px;
    align-content: center;
  }
  .card {
    width: 60px;
    height: 60px;
    border-radius: 20px;
  }
  .cardImage {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
  .cardsContainer {
    gap: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .titleName {
    font-size: 5px;
    text-align: center;
    margin-top: 1rem;
    color: rgb(219, 4, 86);
  }

  .dernierSection {
    /* padding: 0.5rem; */
    display: flex;
    flex-direction: row;
    /* padding: 50px; */
    /* justify-content:space-between; */
    /* margin-left: -20px; */
    background-color: rgba(255, 166, 0, 0);
    padding: 0px;

    margin-top: 50px;
    align-items: center;
    justify-content: center;
  }
  .aboutContainer {
    display: flex;
    flex-direction: column;
    /* padding: 1rem; */
    width: auto;
    background-color: rgba(0, 0, 255, 0);
    padding: 25px 25px 50px 25px;
  }
}
