.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.container .text {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 800;
  text-transform: capitalize;
  color: #c8c8c8;
}
