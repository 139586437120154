* {
    margin: none;
    padding: none;
}

.ShowTrainers h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    font-size: xx-large;
}

.Cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 10px 5px;
}

.CardUser {
    margin: auto;
    width: 373px;
    height: 321px;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    position: relative;
}

.avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.icons {
    padding: 5px;
}

.icons:hover {
    background-color: rgba(189, 189, 189, 0.589);
    border-radius: 70%;
    cursor: pointer;
}

.CardBody {
    width: 373px;
    height: 242px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25), inset 0 0 7px 0 rgba(240, 240, 240, 0.45);
    border-radius: 35px;
    position: absolute;
    top: 70px;
    background-color: white;
    display: grid;
    grid-template-rows: 3fr 3fr;
}

.Config {
    display: flex;
    justify-content: space-between;
}

.ConfigLeft {
    margin-top: 10px;
    margin-left: 7px;
    display: flex;
    width: 80px;
    justify-content: space-around;
    z-index: 5;
}

.ConfigRight {
    margin-top: 10px;
    margin-right: 17px;
}

.Details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.Details h2 {
    margin-left: 20px;
    font-size: 20px;
}

.Details span {
    color: #7A7A7A;
    font-size: 16px;
}


/*///////////////////////////////update course////////////////////////////////////////////////*/

.ModalComponent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ModalComponent hr {
    width: 100%;
    background-color: #c4c4c4;
    border-radius: 50px;
    border: none;
    height: 1px;
}

.DatePicker {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin: 10px;
    height: auto;
}

.DatePickedDiplay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 5px 0;
}