body {
    margin: 0;
}

.video-container {
    width: 100%;
    padding-top: 57%;
    position: relative;
    background-color: black;
}

#my-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.videoPreConnect {
    border-radius: 10px;
    margin: 10px;
    object-fit: fill;
    width: 60%;
    height: 100%
}
.videoPreConnectOffContainer {
    border-radius: 10px;
    margin-right: 30px; 
    width: 300px;
    height: 30vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PreConnectionPage {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.ButtonsPreConnection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.Buttons {   
    flex: 1;
    position: absolute;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 15px;
    
}

.ConnectButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.InfoSpace {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 150px;
}

.my-video-pre-connection{

}

.flex-container {
    height: 90%;
    width: 90%;
    /* margin: 0 auto; */
    /* background-color: #04293A;
    box-shadow: -2px 0 15px 1px rgba(0, 0, 0, 0.25), inset 0 0 9px 2px rgba(0, 0, 0, 0.103); */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container {
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    max-height: 100vh;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.div-send-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.btn-down {
    z-index: 2;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: auto;
}

.Room {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(4, 41, 58);
}

.LeftDiv {
    /* background-color: #04293A;
    box-shadow: -2px 0 15px 1px rgba(0, 0, 0, 0.25), inset 0 0 9px 2px rgba(0, 0, 0, 0.103); */
    width: 75%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    border-radius: 10px;
}

.Streams {
    width: 90%;
    height: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow-y: auto;
}

.Streams video {
    width: 90%;
    margin: auto;
    border-radius: 10px;
}

.footerStream {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25); */
}

.StreamInfo {
    flex: 1;
}

.StreamOptions {
    flex: 1;
}

.rightDiv {
    box-shadow: 4px 0 15px 1px rgba(0, 0, 0, 0.25), inset 0 0 9px 2px rgba(0, 0, 0, 0.103);
    background-color: #04293A;
    width: 23%;
    height: 95%;
    border-radius: 10px;
}
.StreamsVideosContainer{
    width: 100%;
    height: 90%;
    border-radius: 10px;
}
.StreamsChat {
    width: 100%;
    height: 90%;
    border-radius: 10px;
}

.StreamsChat hr {
    width: 80%;
    background-color: rgba(255, 255, 255, 0.507);
    margin: 10px auto;
    height: 1px;
    border: none;
    border-radius: 10px;
}

.MessagesHeader {
    color: white;
    width: 100%;
    height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MessagesBody {
    color: white;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;  
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.MessagesBody::-webkit-scrollbar {
    width: 6px;
}

.MessagesBody::-webkit-scrollbar-track {
    background: transparent;
}

.MessagesBody::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 3px;
}

.message {
    margin: 10px 20px;
}

.MessagesFooter {
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.Message-btn {
    width: 10%;
    color: white;
    cursor: pointer
}

.InputMessage {
    border: 1px solid rgba(0, 0, 0, 0.45);
    background-color: rgba(0, 0, 0, 0.16);
    border-radius: 50px;
    padding: 0 10px;
    height: 40px;
    width: 70%;
    color: white;
}
 