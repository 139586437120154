* {
    margin: none;
    padding: none;
}

.backLogin {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    width: 40%;
    height: 55%;
    box-shadow: 0 1px 23px 2px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
}

.Close {
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 20px 20px;
    color: #1C7182;
    cursor: pointer;
}

.Title {
    font-size: 35px;
    color: #1C7182;
    font-weight: 700;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    outline: none;
    border: none;
    width: 370px;
    padding: 15px;
    border-radius: 10px;
    background-color: #edf5f3;
    margin: 10px 0;
    font-size: 14px;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    height: 20px;
}

.checkBox label {
    margin: 0 5px;
}

.options a {
    color: #407BFF;
    cursor: pointer;
}

.error_msg {
    width: 370px;
    padding: 8px;
    margin: 5px 0;
    font-size: 14px;
    background-color: #f34646;
    color: white;
    border-radius: 5px;
    text-align: center;
}

.Login_btn {
    width: 150px;
    height: 50px;
    border: 2px solid #1E5F74;
    margin-top: 20px;
    background-color: white;
    border-radius: 14px;
    font-size: 18px;
    font-weight: bold;
    color: #1E5F74;
    cursor: pointer;
}

.Login_btn2 {
    width: 250px;
    height: 50px;
    border: 2px solid #1E5F74;
    margin-top: 20px;
    background-color: white;
    border-radius: 14px;
    font-size: 18px;
    font-weight: bold;
    color: #1E5F74;
    cursor: pointer;
}

.signup {
    display: flex;
    justify-content: end;
    align-items: center;
    color: #263238;
    font-size: 15px;
    margin: 3px 0;
    padding-left: 7px;
}

.explication {
    max-width: 80%;
    color: rgb(128, 128, 128);
    text-align: center;
    margin-bottom: 10px;
}