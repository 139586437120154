@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.container {
  width: 100%;
  height: calc(100% - 86px);
  max-width: 873px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 75px;
}

.group {
  display: flex;
  width: 100%;
  gap: 64px;
}

.stack {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 21px;
}

.imgContainer img {
  width: 163px;
  height: 163px;
  border-radius: 50%;
  object-fit: cover;
}

.title {
  font-size: var(--font-size-large);
  font-weight: 800;
  font-size: 2.5rem;
  width: fit-content;
  color: var(--text-color);
  position: relative;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.container form {
  width: 100%;
  margin: 69px 0;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.container form .buttons button {
  border: 1px solid #3e4678;
  border-radius: 12px;
  font-size: 1.75rem;
  padding: 11px 47px;
  color: #3e4678;
  margin-top: 2rem;
}

.container form .buttons button:last-child {
  background: #3e4678;
  color: white;
  font-weight: 600;
}

@media (max-width: 768px) {
  .container {
    gap: 20px;
    justify-content: center;
  }
  .inputContainer > input,
  .inputContainer > select {
    height: 50px;
    padding: 15px 20px;
  }
  .container form {
    margin-top: 30px;
  }
  .imgContainer img {
    width: 100px;
    height: 100px;
  }
  .group {
    gap: 15px;
  }
  .stack {
    gap: 15px;
  }
}
