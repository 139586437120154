.Success {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 70%;
    margin: auto;
}

.Categories {
    width: 95%;
    margin: auto;
    margin-bottom: 10px !important;
}

.ButtonAddCategory {
    width: 90%;
    margin: 15px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}