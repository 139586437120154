.timerContainer {
  position: relative;
  width: 100px;
  height: 100px;
}

.timerBg,
.timerProgress {
  fill: none;
  stroke-width: 3;
}

.timerBg {
  stroke: #eee;
}

.timerProgress {
  stroke: #f49d6e;
  transition: stroke-dashoffset 1s linear;
}

.timeText {
  fill: #fff;
  font-size: 0.2rem;
  font-weight: bold;
}

.timeRemaining {
  fill: #fff;
  font-size: 0.3rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .timerContainer {
    width: 75px;
    height: 75px;
  }

  .timerBg,
  .timerProgress {
    stroke-width: 2;
  }

  .timeText {
    font-size: 4px;
  }

  .timeRemaining {
    font-size: 6px;
  }
}
