.spotlightOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spotlightContent {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.searchWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 0 5px;
  border-radius: 0.5rem;
}

.searchInput {
  flex-grow: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  border: none;
  outline: none;
}

.actionsList {
  max-height: 300px;
  overflow-y: auto;
}

.actionItem {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.actionItem:hover {
  background-color: #f0f0f0;
}

.leftSection {
  margin-right: 10px;
  font-size: 30px;
}

.actionDetails {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold;
  align-self: flex-start;
}

.description {
  color: #666;
}

.noResults {
  padding: 20px;
  text-align: center;
  color: #888;
}

.closeButton {
  margin-top: 20px;
  background: #f00;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}
