.buttons button {
  display: flex;
  align-items: flex-start;
  gap: 19px;
  padding: 15px 0 15px 26px;
  border: 1px solid var(--primary-color);
  background-color: var(--secondary-color);
  color: var(--text-color);
  width: 100%;
  cursor: pointer;
  border-radius: 23px;
  transition: background-color 0.3s ease;
}

.buttons button.active {
  background-color: var(--primary-color);
}

@media (max-width: 1200px) {
  .buttons {
    width: fit-content;
  }
  .buttons button {
    width: 6rem;
    display: grid;
    place-items: center;
    padding: 15px 0;
  }
  .buttons button span {
    display: none;
  }
}
