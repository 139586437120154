.container {
  background-color: white;
  width: 19rem;
  height: 25.19rem;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.container .image {
  width: 100%;
  min-width: 17rem;
  object-fit: cover;
  height: 13rem;
  border-top-right-radius: 20px;
}

.content {
  padding: 1.2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.1rem;
  min-height: 11rem;
}

.content .text {
  display: flex;
  justify-content: space-between;
}

.content .text > p:first-child {
  background: #fdf4a8;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  max-width: 65%;
  color: #2c2c2c;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content .text > p:last-child {
  font-size: 0.9rem;
  font-weight: 400;
  color: #343434;
}

.content .title {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: flex-start;
  gap: 0.9rem;
}

.content .title > p:first-child {
  font-size: 0.9rem;
  font-weight: 600;
  color: #706f6f;
  align-self: flex-start;
}

.content .title > p:last-child {
  font-size: 1rem;
  font-weight: 600;
  align-self: flex-start;
  color: #343434;
}

.rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rating span {
  color: #818181;
  font-size: 0.8rem;
}

.stars {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.avatarGroup {
  display: flex;
  align-items: center;
}
.avatarGroup img {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-left: -0.5rem;
}

.container button {
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  width: 2rem;
  height: 2rem;
  color: white;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  background: #cd6214;
  border-top-left-radius: 20px;
}

.container button:hover {
  background: #f9a03f;
}

.type {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.type img {
  width: 1.5rem;
}

.type[data-type="training"] {
  border: 2px solid var(--primary-color);
}

.type[data-type="course"] {
  border: 2px solid var(--secondary-color);
}

.type[data-type="voucher"] {
  border: 2px solid red;
}
