@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
    margin: 0 5px;
}

.nav {
    width: 100%;
    height: 130px;
}

.scndNav {
    width: 97%;
    height: 60px;
    margin: auto;
    border-radius: 13px;
    border: 3px solid #6FC2D0;
    display: flex;
    justify-content: end;
}

.Links {
    width: 100%;
    flex: 2;
    display: flex;
    justify-content: end;
    align-items: center;
}

.Links a {
    text-decoration: none;
    margin: 0 5px;
}

.nav_btn {
    font-size: 22px;
    color: #1C4B82;
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
}

.nav_btn_special {
    composes: nav_btn;
    border: 2px solid #FFA41B;
    padding: 5px;
    border-radius: 14px;
}

.nav_btn_special:hover {
    border: 2px solid #1C4B82;
    color: #FFA41B;
    padding: 5px;
    border-radius: 14px;
}

.search {
    flex: 1.4;
    display: flex;
    justify-content: end;
}

.search input {
    height: 50%;
    align-self: center;
    margin-right: 60px;
    border: 2.5px solid #9B0000;
    border-radius: 20px;
    width: 50%;
    padding: 0 10px;
}

@media (max-width:756px) {
    .search {
        justify-content: center;
    }
    .search input {
        margin-right: 0px;
        width: 70%;
    }
}

.search input:focus {
    outline: none;
}

.firstNav {
    width: 95%;
    height: 70px;
    margin: auto;
    display: flex;
}

.logo {
    flex: 1;
    font-size: 36px;
    font-weight: 900;
    color: #1C4B82;
    align-self: center;
}

.logo p {
    color: #1C4B82;
}

.contact {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 20px;
}

.nav_btn_special_contact {
    composes: nav_btn;
    border: 2px solid #1C4B82;
    padding: 5px 10px;
    border-radius: 14px;
}

.nav_btn_special_contact:hover {
    color: white;
    background-color: #1C4B82;
    padding: 5px 10px;
    border-radius: 14px;
}

.language {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid #1C4B82;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.language img {
    width: 50px;
    height: 50px;
}

.language p {
    position: absolute;
    color: white;
    font-weight: bold;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: rgba(0, 0, 0, 0.3);
}


/*///////////////////////*/

.CourseForm {
    background: url('../assets/ProfileBack.svg') no-repeat top;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Success {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 70%;
    margin: auto;
}

.MainDiv {
    width: 90%;
    height: 100%;
    /* background-color: #1C4B82; */
    display: grid;
    grid-template-columns: 3.5fr 1fr;
}

.leftSection {
    /* background-color: #6FC2D0; */
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.ImgUploadSuction {
    width: 100%;
    height: 50vh;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.InfosSuction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rightSection {
    position: fixed;
    /* background-color: #1c822d; */
    width: 25%;
    height: 60vh;
    right: 90px;
    bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scndInfos {
    padding: 20px 0;
    background-color: white;
    height: 90%;
    width: 90%;
    position: sticky;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    display: block;
}

.scndInfos .FormControl {
    margin: 10px 30px;
}

.Date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
    height: auto;
}

.DatePicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: 10px;
    height: auto;
}

.ValidateIcon {
    margin: auto 5px;
    cursor: pointer;
}

.DatePickedDiplay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px;
    height: auto;
}

.footerOptimised {
    width: 100%;
    height: 100px;
    background-color: #0E2256;
    display: flex;
    color: white;
}

.footerOptimised h1 {
    color: #F8C498;
    font-size: 40px;
}

.footerOptimisedLeft {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 100px;
    font-size: 12px;
    font-weight: 400;
}

.footerOptimisedRight {
    padding-right: 10px;
    flex: 2;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    margin: 0 90px;
}

.footerOptimisedRight div {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 10px;
}

.footerOptimisedRight div p {
    margin: 0 5px;
}


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/


/*//////////////////////////////////////////////////////*/

.FirstFormSection {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 10px;
    padding: 5px;
    width: 70%;
    height: 50vh;
    /* background-color: #1C4B82; */
}

.ImgSelector {
    flex: 1;
    height: 100%;
    /* background-color: #825e1c; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.PrimaryInfos {
    flex: 1.2;
    /* background-color: #821c24; */
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.SecondFormSection {
    /* background-color: #825e1c; */
    padding: 5px;
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin: auto;
}

.FormControl {
    margin: 20px 0;
}

.error_msg_Phone {
    width: 370px;
    font-size: 12px;
    color: #f34646;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}