.aboutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5%;
}



.aboutContainercollab {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 5%;
  padding-left: 6.5rem;
  padding-right: 6.5rem;
}

.dernierSection {
  display: flex;
  flex-direction: row;
  background-color: orange; 
  padding-left: 6.5rem;
  padding-right: 6.5rem;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
}

.description {
  font-size: 20px;
  margin-top: 1rem;
  color: black;
  line-height: 40px;
  font-weight: bold;
  font-family: "Lato";
  display: flex;
  justify-content: center;
  align-self: center;
  flex-direction: row;
}

.descriptionpar {
  margin-top: 50px;
}

.imageAboutUs {
  align-self: center;
}

.imageWrapper {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  flex-direction: row;
}

.aboutTitle {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 50px;
}

.underline {
  width: 10%;
  background-color: #CD6214;
  padding-top: 5px;
  border-radius: 100px;
  margin-top: 20px;
}

.imageConsultant {
  width: 100%;
  height: 100%;
}

.title {
  font-size: 25px;
  color: #cd6214;
  margin-bottom: 5%;
  text-align: center;
  font-weight: 900;
}

.subtitle {
  font-size: 40px;
  color: #1c4b82;
  text-transform: capitalize;
}

.collaborateTitle {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  padding-bottom: 50px;
}

.ourPartners {
  margin-top: 30px;
  width: 100%;
  height: 30%;
  display: inline-block;
}

.ourPartners div {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourPartners div img {
  max-width: 100px;
  margin: 20px;
  filter: grayscale(1);
}

.ourPartners div img:hover {
  filter: grayscale(0);
}

.readButton {
  width: 6.87rem;
  height: 2.5rem;
  background-color: #1c4b82;
  color: white;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  font-family: "Nunito";
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.readButton:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
}

.consultantSection {
  display: flex;
  flex-direction: row;
  justify-content: center; 
  gap: 5px;
  padding-bottom: 8%;
}

.allParaghraphe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%
}

.descriptionParagh {
  text-align: center;
  margin-bottom: 5%;
  line-height: 20px;
  font-size: 17px;
  width: 100%;
}

.consultantsTitle {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  width: 100%;
  margin-bottom: 5%;
}

.button {
  display: flex;
  justify-content: center;
}

.readButton, .applyButton {
  display: flex;
  justify-content: center; /* Existing property */
  align-items: center;
  width: 6.87rem;
  height: 2.5rem;
  background-color: #3E4678;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.4);
  color: white;
  font-size: 15px; /* Adjust font size as needed */
  font-weight: bold;
  text-align: center;
  font-family: "Nunito";
  border: none;
  border-radius: 6px;
  cursor: pointer;
}


.applyButton:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
  background-color: #CD6214;
}

.subtitle {
  font-size: 40px;
  color: #1c4b82;
  text-transform: uppercase;
}
@media (min-width:1301px) {
  .aboutContainer {
    padding-bottom: 10%;
  }
  .descriptionParagh {
    font-size: 20px;
    line-height: 40px;
    width: 80%;
  }
  .aboutContainercollab {
    padding-bottom: 10%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .dernierSection {
    margin-top: 50px;
  }
  .description {
    font-size: 18px;
    line-height: 30px;
  }
  .descriptionpar {
    margin-right: 0px;
  }
  .imageAboutUs {
    width: 100%;
    background-color: rgba(17, 243, 36, 0);
  }
  .readButton, .applyButton {
    display: flex;
    justify-content: center; /* Existing property */
    align-items: center;
    width: 6.87rem;
    height: 2.5rem
}
.subtitle {
  font-size: 60px
  
}
}


@media (min-width: 951px) and (max-width: 1300px){
  .descriptionpar {
    margin-right: 0px;
  }
  .imageAboutUs {
    width: 100%;
    background-color: rgba(17, 243, 36, 0);
  }
  .readButton, .applyButton {
    display: flex;
    justify-content: center; /* Existing property */
    align-items: center;
    width: 6.87rem;
    height: 2.5rem;
    background-color: #3E4678;
    color: white;
    font-size: 12px; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    font-family: "Nunito";
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .underline {
    width: 10%;
    padding-top: 3px;
    margin-top: 1px;
  }
  .description {
    font-size: 15px;
    line-height: 25px;
  }
  .subtitle {
    font-size: 40px;
  }
  .readButton {
    margin-top: 10px;
  }
  .descriptionParagh {
    font-size: 15px;
    line-height: 25px;
    width: 80%;
  }
}

@media (min-width: 732px) and (max-width: 950px) {
  .aboutContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
  .applyButton {
    width: 6.87rem;
    height: 2.5rem;

    color: white;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    font-family: "Nunito";
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 5%;
  }
  .descriptionParagh {
    text-align: center;
    margin-bottom: 5%;
    line-height: 25px;
    font-size: 15px;
    width: 100%;
  }
  .consultantsTitle {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    margin-bottom: 5%;
  }
  .aboutContainercollab {
    padding-left: 10px;
    padding-right: 10px;
  }
  .dernierSection {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .description {
    font-size: 15px;
    line-height: 25px;
    margin-right: 0px;
  }
  .descriptionpar {
    margin-right: 0px;
  }
  .imageAboutUs {
    width: 100%;
    background-color: rgba(17, 243, 36, 0);
  }
  .underline {
    width: 10%;
    padding-top: 3
}
.title {
  font-size: 18px;
  color: #cd6214;
  margin-bottom: 5%;
  text-align: center;
}
}
@media (max-width: 700px) and (min-width: 550px){
  .card {
    width: 120px;
    height: 120px;
  }
  .cardsContainer {
    gap: 20px;
    margin-left: 50px;
    margin-right: 50px;
  }
  .aboutTitle {
    padding-bottom: 5px;
    margin-bottom: 5%;
    font-size: 20px;
  }
  
  .title {
    font-size: 13px;
    margin-top: 0%;
    margin-bottom: 3%;
    color: #cd6214;
    font-weight: bolder;
    text-align: center;
  }
  .description {
    font-size: 5px;
    margin-right: 50px;
    width: 100%;
  }
  .aboutContainer {
    width: 100%;
    padding: 5px;
  }
  .dernierSection {
    flex-direction: row;
    background-color: orange;
    padding: 0px;
    margin-top: 50px;
  }
  .allParaghraphe {
    padding: 20px;
  }
  .consultantsTitle {
    font-size: 17px;
    align-self: center;
    width: 100%;
    white-space: nowrap;
  }
  .imageConsultant {
    width: 100%;
    height: 100%;
  }
  .applyButton {
    font-size: 8px;
    width: 6rem;
    
  }
  .descriptionParagh {
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
  }
}
@media (min-width: 701px) and (max-width: 731px){
  .consultantsTitle { 
    font-size: 23px;
    align-self: center;
    width: 100%;
    white-space: nowrap;
  }
  .title {
    font-size: 20px;
    margin-top: 0%;
    margin-bottom: 3%;
    color: #cd6214;
    font-weight: bolder;
    text-align: center;
  }
  .readButton, .applyButton {
    display: flex;
    justify-content: center; /* Existing property */
    align-items: center;
    width: 6.87rem;
    height: 2.5rem;
    
    color: white;
    font-size: 10px; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    font-family: "Nunito";
    border: none;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .descriptionParagh {
    font-weight: normal;
    font-size: 12px;
    line-height: 24 px;
  }
  .consultantsTitle {
    font-size: 17px;
    align-self: center;
    width: 100%;
    white-space: nowrap;
  }
}
@media (max-width: 550px) and (min-width: 501px) {
  
  .title {
    font-size: 10px;
    margin-top: 0%;
    margin-bottom: 3%;
    color: #cd6214;
    font-weight: bolder;
    text-align: center;
  }
 
  
  .consultantsTitle {
    font-size: 10px;
  }
  .descriptionParagh {
    font-size: 10px;
    line-height: 15px;
  }
  .applyButton {
    font-size: 8px;
  }
 
}
@media (max-width: 500px) and (min-width: 401px) {
  .descriptionParagh {
    font-weight: normal;
    font-size: 8px;
    line-height: 10px;
  }
  .applyButton {
    font-size: 6px;
    width: 35%;
    height: 250%;
  }
  .title {
    text-align: center;
    font-size: 10px;
    margin-top: 0%;
    margin-bottom: 3%;
    color: #cd6214;
    font-weight: bolder;
  }
  .description {
    font-size: 3px;
    margin-right: 50px;
    width: 100%;
  }
  .aboutTitle {
    font-size: 2px;
  }
 
  .card {
    width: 60px;
    height: 60px;
    border-radius: 20px;
  }
  .cardImage {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
  .cardsContainer {
    gap: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .dernierSection {
    flex-direction: row;
    background-color: rgba(255, 166, 0, 0);
    padding: 0px;
    margin-top: 50px;
  }
  .consultantsTitle { 
    font-size: 10px;
    align-self: center;
    width: 100%;
    white-space: nowrap;
  }
  .aboutContainer {
    width: auto;
    padding: 25px 25px 50px 25px;
  }
}
@media (max-width: 400px) {
  .title {
    font-size: 10px;
    margin-top: 0%;
    margin-bottom: 3%;
    color: #cd6214;
    font-weight: bolder;
  }
  .consultantSection {
    margin-bottom: 10%;
    margin-top: 10%;
  }
  .description {
    font-size: 2px;
    margin-right: 50px;
    width: 100%;
  }
  .aboutTitle {
    font-size: 5px;
  }
  
 .descriptionParagh {
   font-weight: normal;
    font-size: 6px;
    line-height: 8px;
  }
  .cardsContainer {
    gap: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .dernierSection {
    flex-direction: row;
    background-color: rgba(255, 166, 0, 0);
    padding: 0px;
    margin-top: 50px;
  }
  .aboutContainer {
    width: auto;
    padding: 20px 20px 50px 20px;
  }
 
  .card {
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }
  .cardImage {
    width: 10px;
    height: 10
}
.applyButton {
  font-size: 6px;
  height: 250%;
  width: 40%;
}
  .consultantsTitle {
    font-size: 10px;
    align-self: center;
    width: 100%;
    white-space: nowrap;
  }
}
@media (max-width: 350px) {
  .title {
    font-size: 7px;
    margin-top: 0%;
    margin-bottom: 3%;
    color: #cd6214;
    font-weight: bolder;
  }
  .consultantsTitle {
    font-size: 8px;
  }
  .consultantSection {
    margin-bottom: 10%;
    margin-top: 10%;
  }
  .description {
    font-size: 2px;
    margin-right: 50px;
    width: 100%;
  }
  .aboutTitle {
    font-size: 5px;
  }
  .descriptionParagh {
    font-weight: normal;
    font-size: 6px;
    line-height: 8px;
  }
  .cardsContainer {
    gap: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
}