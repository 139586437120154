.background_container {
  background: url("../assets/background-image.png") no-repeat center center;
  background-size: cover;
  height: 200px;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: fixed;
  width: 100%;
  z-index: 999;

  @media (max-width: 768px) {
    border-radius: 0 0 20px 20px;
  }
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 200px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1000px;

  @media (max-width: 992px) {
    padding: 0 3rem;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.header .details > p:nth-child(2) {
  font-size: 34px;
  font-weight: 800;
  color: white;
}

.header .details > button:last-child {
  font-size: 18px;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;
  cursor: pointer;
}

.header .quizLogo {
  height: 50px;
}

.logo {
  position: relative;
  margin: 1rem auto 0;
  width: fit-content;
}

.logo img {
  width: 140px;
}

.logo p {
  font-size: 0.4rem;
  font-weight: 300;
  line-height: 1.8rem;
  color: var(--text-color);
  position: absolute;
  bottom: -8px;
  font-weight: 400;
  right: 0;
}

@media (max-width: 768px) {
  .background_container {
    height: 150px;
  }

  .container {
    margin-top: 150px;
  }

  .details .quizLogo {
    height: 30px;
  }
  .details > p:nth-child(2) {
    font-size: 2rem !important;
  }
  .details button:last-child {
    font-size: 10px !important;
  }
  .logo {
    margin-top: 1rem;
  }
  .logo img {
    width: 100px;
  }
}
