:root {
  --text-color: #ffffff;
  --font-size-large: 2.5rem;
  --font-size-medium: 2rem;
  --border-radius-large: 50px;
  --border-radius-circle: 50%;
  --border-thickness: 5px;
}

.background_container {
  background: url("../assets/background-image.png") no-repeat center center;
  background-size: cover;
  height: 31vh;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.cardTitle {
  font-size: 2rem;
  font-weight: 800;
  width: fit-content;
  margin: 0 auto;
  position: relative;
}
.title {
  text-transform: capitalize;
  font-size: var(--font-size-large);
  font-weight: 800;
  position: absolute;
  width: fit-content;
  color: var(--text-color);
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}

.title::after,
.cardTitle::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.imgProfile {
  position: absolute;
  border-radius: var(--border-radius-circle);
}

.imgProfile {
  bottom: -50%;
  left: 0;
}

.imgContainer img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.main {
  display: flex;
  flex-wrap: nowrap;
  gap: 1.25rem;
  margin: 5rem 0px;
}

.main .mainContainer {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.main .sideBar {
  flex: 1;
  max-width: 452px;
}

.sideBar {
  width: 600px;
  height: 100%;
  border-radius: 2.5rem;
  box-shadow: 0px 0px 4px var(--shadow-color);
  padding: 35px 62px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sideBarContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

/* media Query */
@media (max-width: 1200px) {
  .cardTitle {
    font-size: 1.25rem;
  }

  .sideBar {
    padding: 30px 24px;
    width: fit-content;
    flex: 0 !important;
  }
}

@media (max-width: 768px) {
  .main {
    margin-top: 22px;
  }

  .img_container,
  .title,
  .imgProfile,
  .info,
  .sideBar {
    display: none;
  }

  .background_container {
    height: 15dvh;
  }
  .personnelInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .imgContainer img {
    width: 100px;
    height: 100px;
  }

  .mainContainer {
    align-items: center !important;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .userInfo > div {
    text-align: center;
  }

  .userInfo > div > p {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
