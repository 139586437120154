.catalog{
    width: 50%; 
    margin: 0 auto; 
    z-index: 2;
    color: white;
  }




  
  

  