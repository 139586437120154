.leftSectionProfile {
  width: 100%;
  min-height: 73vh;
}

.ActuDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: 5px;
}

.MuiDataGrid-root {
  border-bottom: none;
  background-color: red !important;
}

.currencySelect {
  height: 60px !important;
  transform: scale(0.7);
  background-color: white;
}
