hr {
  border: solid 3px #cd6214;
  width: 5%;
  margin-left: 10%;
  font-weight: 30px;
  height: 20%;
  border-radius: 20px;
}

.emptyBox {
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.paddingbottom {
  padding-bottom: 2%;
}

b {
  margin-left: 10%;
  font-size: 60px;
  margin-bottom: 30px;
}

.container-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

.underline {
  width: 10%;
  background-color: #cd6214;
  padding-top: 5px;
  border-radius: 100px;
  margin-top: 20px;
}

.features {
  font-size: 40px;
  margin-top: 5%;
  margin-left: 5%;
  margin-bottom: 4%;
}

.container-grid {
  width: 100%;
  display: inline-block;
}

.imagebackground {
  width: 100%;
  height: 90%;
}

.container {
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.col {
  margin-left: 50px;
  margin-top: 2%;
}

.btn_style {
  margin-top: 4%;
  margin-left: 1%;
  background-color: #3e4678;
  width: 10%;
  padding: 10px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

.btn_style:hover {
  background-color: #cd6214;
}

.row1 {
  display: inline-flex;
}

.row2 {
  display: inline-flex;
  margin-top: 20px;
}

.text_btn {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
 
.background_container {
  background: radial-gradient(circle, #345AA4 20%, #3E4678 100%);
  height: auto;
  border-radius: 0px 0px 50px 50px;
   
}

.content_overlay {
  position: relative;
  bottom: 0;
  width: 85%;
   
  margin-inline: auto;
  margin-top: 30px;
 

}
.box_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.img_container {
  width: 13rem;
  height: 13rem;
}
.img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.breadcrumb {
  display: flex;
  align-items: center;
}

.breadcrumb p {
  font-size: 16px;
  font-weight: 500;
}

.breadcrumb img {
  margin: 0 5px;
}

.breadcrumb-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 50px;
  width: 100%;
  padding-inline: 5px;
  margin-inline: auto;
}

.breadcrumb-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.breadcrumb-text {
  font-weight: 500;
  cursor: pointer;
}

.innerDisplay{
  width: 90%;
  margin-inline: auto;
}

.box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 16px;
  margin-top: 1rem;
  min-height: 40dvh;
}

@media (max-width: 768px) {
  .background_container {
    height: 19vh;
  }
  .content_overlay {
    bottom: 20px;
  }
  .img_container {
    display: none;
  }
}
