    * {
        margin: none;
        padding: none;
    }
    
    .CourseForm {
        background: url('../../assets/courseBack.png') no-repeat top;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .Success {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 70%;
        margin: auto;
    }
    
    .MainDiv {
        width: 90%;
        height: 100%;
        /* background-color: #1C4B82; */
        display: grid;
        grid-template-columns: 3.5fr 1fr;
    }
    
    .leftSection {
        /* background-color: #6FC2D0; */
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .ImgUploadSection {
        width: 100%;
        height: 50vh;
        /* background-color: aqua; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .InfosSuction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .rightSection {
        position: fixed;
        /* background-color: #1c822d; */
        width: 25%;
        height: 60vh;
        right: 90px;
        bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .scndInfos {
        padding: 20px 0;
        background-color: white;
        height: 90%;
        width: 90%;
        position: sticky;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
        display: block;
    }
    
    .scndInfos .FormControl {
        margin: 10px 30px;
    }
    
    .VideosUpload {
        width: 80%;
        height: 70px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        margin: 10px 30px;
    }
    
    .fileUploaded {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        height: 60px;
        border-radius: 5px;
        background-color: #E9F0FF;
        margin: 5px;
    }
    
    .IconFile {
        width: 10%;
        color: #6990F2;
    }
    
    .TitleFile {
        color: black;
        width: 80%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 40px;
    }
    
    .TitleFile span {
        color: #404040;
        font-size: 11px;
    }
    
    .TitleFile h6 {
        font-size: 14px;
    }
    
    .FileSelector {
        width: 70%;
        height: 200px;
        border-radius: 5px;
        background-color: #E9F0FF;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .selectFiles {
        width: 88%;
        height: 140px;
        margin: auto;
        border: 2px dashed #6990F2;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .selectFiles2 {}
    
    .Btn_save {
        height: 50px;
        width: 80%;
        margin: 20px auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .ValidateIcon {
        margin: auto 5px;
        cursor: pointer;
    }
    
    .footerOptimised {
        width: 100%;
        height: 100px;
        background-color: #0E2256;
        display: flex;
        color: white;
    }
    
    .footerOptimised h1 {
        color: #F8C498;
        font-size: 40px;
    }
    
    .footerOptimisedLeft {
        flex: 2;
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 0 100px;
        font-size: 12px;
        font-weight: 400;
    }
    
    .footerOptimisedRight {
        padding-right: 10px;
        flex: 2;
        display: flex;
        justify-content: end;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        margin: 0 90px;
    }
    
    .footerOptimisedRight div {
        display: flex;
        justify-content: end;
        align-items: center;
        margin: 0 10px;
    }
    
    .footerOptimisedRight div p {
        margin: 0 5px;
    }
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    /*//////////////////////////////////////////////////////*/
    
    .FirstFormSection {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        margin-top: 10px;
        padding: 5px;
        width: 70%;
        height: 50vh;
        /* background-color: #1C4B82; */
    }
    
    .ImgSelector {
        flex: 1;
        height: 100%;
        /* background-color: #825e1c; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .PrimaryInfos {
        flex: 1.2;
        /* background-color: #821c24; */
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }
    
    .SecondFormSection {
        /* background-color: #825e1c; */
        padding: 5px;
        width: 70%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        margin: auto;
    }
    
    .FormControl {
        margin: 20px 0;
    }
    /***********************************************************************************/
    /***********************************************************************************/
    /***********************************************************************************/
    /***********************************************************************************/
    /***********************************************************************************/
    /***********************************************************************************/
    /***********************************************************************************/
    /***********************************************************************************/
    
    .AddTest {
        border: 1px dashed rgb(169, 169, 169);
        width: 80%;
        height: auto;
        margin-bottom: 20px;
        border-radius: 5px;
    }
    
    .AddTest h3 {
        margin: 10px;
    }
    
    .Questions {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .QCMdiv {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
    }
    
    .QCMdiv h4 {
        margin: 10px;
        color: rgb(45, 45, 45);
    }
    
    .AddQCM {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
    }
    
    .AddQCM h5 {
        margin: 10px;
        color: rgb(109, 109, 109);
    }
    
    .QRdiv {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
    }
    
    .QRdiv h4 {
        margin: 10px;
        color: rgb(45, 45, 45);
    }
    
    .AddQR {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
    }
    
    .AddQR h5 {
        margin: 10px;
        color: rgb(109, 109, 109);
    }
    
    .AddQCMForm {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
    }
    
    .QCMShowLists {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        padding-bottom: 10px;
    }
    /* .QRShowLists {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */
    /***********Ressources***************/
    
    .AddRessources {
        border: 1px dashed rgb(169, 169, 169);
        width: 80%;
        height: auto;
        margin-bottom: 20px;
        border-radius: 5px;
    }
    
    .AddRessources h3 {
        margin: 10px;
    }
    
    .Ressources {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .AddRessource {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
    }
    
    .AddRessource h5 {
        margin: 10px;
        color: rgb(109, 109, 109);
    }
    
    .Ressources {
        border: 1px solid rgb(113, 113, 113);
        margin: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
    }
    
    .fileUploaded {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
        height: 60px;
        border-radius: 5px;
        background-color: #E9F0FF;
        margin: 5px;
    }
    
    .IconFile {
        width: 10%;
        color: #6990F2;
    }
    
    .TitleFile {
        color: black;
        width: 80%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 40px;
    }
    
    .TitleFile span {
        color: #404040;
        font-size: 11px;
    }
    
    .TitleFile h6 {
        font-size: 14px;
    }