.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.modal p {
  font-size: 1.2rem;
  font-weight: 600;
}

.modal button {
  border-radius: 5px;
}

.modal button span {
  font-size: 1rem !important;
  font-weight: 400;
}

.modal form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.modal .inputForm input {
  height: 30px;
}

.modal .inputForm label {
  font-size: 14px;
  text-align: start;
}

.modal .inputForm select {
  height: 44px;
  padding: 0 20px;
}

.modal .buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
}

.searchResultsContainer {
  width: 100%;
  position: absolute;
  max-height: 250px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.resultItem {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.resultItem:hover {
  background-color: var(--primary-light-color);
}

.resultItemSelected {
  background-color: var(--primary-color);
  color: #ffffff;
}

.resultItemText {
  font-size: 16px;
}

.noResults {
  padding: 10px;
  font-size: 14px;
  color: #888;
  text-align: center;
}
