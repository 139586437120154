.containerInfo {
  display: flex;
  width: 100%;
  gap: 1.5rem;
}

.containerInfo > div {
  flex: 1;
}

.containerHeader {
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 26%);
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding: 13px 0;
}

.imgContainer img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.info div {
  text-align: center;
}

.info .name p:first-child {
  font-size: 1.5rem;
  font-weight: 800;
  margin-top: 8px;
}

.info .name p:nth-child(2) {
  font-weight: 600;
  font-size: 18px;
  margin-top: 10px;
}

.info .contact {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 11px;
}

.container {
  width: 100%;
  max-width: 452px;
  border-radius: 2.5rem;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 26%);
  padding: 1.5rem 3.5rem;
}

.container ul {
  list-style-type: disc;
  color: #2c2c2c;
}

.container ul li {
  font-weight: 800;
  font-size: 1rem;
  line-height: 2;
}

.container span {
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 2;
  padding-left: 5px;
}

.programs {
  display: flex;
  font-size: 1rem;
  gap: 20px;
}

.programs div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.seeMore {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: underline;
  line-height: 2;
  color: var(--secondary-color);
}

/* Modal styles */

.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  width: 800px;
  border-radius: 30px;
  background-color: var(--text-color);
  box-shadow: 24px;
  padding: 0.8rem 1rem;
}

.icon {
  color: var(--primary-color);
  display: block;
  margin-left: auto;
  cursor: pointer;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 15px 40px;
}

.header {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
}

.title {
  font-size: 2rem;
  font-weight: 800;
  width: fit-content;
  position: relative;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.input {
  display: flex;
  align-items: center;
  border: 1px solid var(--primary-color);
  border-radius: 23px;
  width: 400px;
  padding: 7px 23px;
}

.input span {
  font-size: 1rem;
  font-weight: 600;
}

.input > div:first-child {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.input > div:first-child::after {
  content: "";
  position: absolute;
  right: -10px;
  width: 1px;
  height: 20px;
  background-color: #818181;
  height: 100%;
}

.input input {
  border: none;
  width: 100%;
  height: 100%;
  padding-left: 1.2rem;
  outline: none;
}

.programsDetails {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0px 20px;
}

.programsDetails div {
  flex: 1;
}

.programsDetails div:nth-child(1),
.programsDetails div:nth-child(2) {
  min-width: 150px;
}

.programsDetails ul {
  list-style-type: disc;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.programsDetails ul li {
  font-size: 1rem;
}

.editSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editSection button:first-child,
.editSection button:last-child {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 10px 45px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
}

.editSection button:last-child {
  background-color: var(--primary-color);
  width: fit-content;
  margin-left: auto;
}

.editSection button:first-child {
  width: 100%;
}

.editSection form {
  min-width: 10rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.editSection form > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.editSection form > div label {
  font-size: 0.8rem;
  color: #585858;
}

.editSection form > div input {
  width: 100%;
  border-radius: 10px;
  outline: none;
  height: 36px;
  border: 1px solid var(--primary-color);
  padding: 0 15px;
}

.editSection form .ouiNonButton {
  display: flex;
  gap: 3%;
}

.editSection form .ouiNonButton input {
  background: none;
}

.editSection form .ouiNonButton input.oui {
  background: var(--secondary-color);
  color: white;
}

.editSection form .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.editSection form .buttons button {
  width: fit-content;
  border: 1px solid var(--primary-color);
}

.editSection form .buttons button:first-child {
  background-color: transparent;
  color: var(--primary-color);
}

/* media Query */
@media (max-width: 992px) {
  .container {
    max-width: none;
    padding: 2rem 4rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 2rem 4rem;
    width: fit-content;
  }
  /* .containerInfo {
    margin-top: 22px;
  } */

  .imgContainer img {
    width: 100px;
    height: 100px;
  }
  .modalContainer {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
