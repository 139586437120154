.descriptionpar {
  margin-top: 5%;
  margin-right: 10%;
  margin-bottom: 0%;
  line-height: 35px;
  font-size: 20px;
  font-weight: bold;
  text-align: justify; /* Add this line */
}

/* The rest of your CSS remains unchanged */
.aboutContainer, .aboutContainercollab {
  display: flex;
  flex-direction: column;
  width: 100%;
  
}


.subtitleContainer {
  margin-top: 1%;
  margin-bottom: 2%;
  text-align: left;
}

.subtitle {
  margin-left: 4%;
  color: #1c4b82;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: -2%;
}


.description {
  margin-top: -2%;
  color: black;
  font-weight: small;
  margin-bottom: -5%;
  display: flex;
  justify-content: left;
  align-self: left;
  flex-direction: row;
  margin-left: 4%;
}

.imageAboutUs {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 0%;
}

.imageWrapper {
  margin-bottom: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.aboutTitle, .collaborateTitle, .consultantsTitle {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 8%;
  margin-top: 0%;
}

.underline {
  width: 7%;
  background-color: #cd6214;
  padding-top: 4px;
  border-radius: 100px;
  margin-top: 7px;
}

.readButton, .applyButton {
  width: 6.87rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  background-color: #3E4678;
  color: white;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  font-family: "Nunito";
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding-top: 1.5%;
  padding-bottom: 2%;
  margin-bottom: 2%;
}

.readButton:hover, .applyButton:hover {
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.503);
}

/* Media queries for responsiveness */
@media (min-width: 1025px) {
  .descriptionpar {
    margin-bottom: 0%;
    line-height: 30px;
    margin-top: 0%;
    font-size: 20px;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 800;
  }
  .aboutTitle {
    font-size: 25px;
    margin-left: 4%;
    margin-bottom: 0%;
    font-weight: 800;
    margin-top: 5%;
  }

  .underline {
    width: 7%;
    background-color: #cd6214;
    padding-top: 4px;
    border-radius: 100px;
    margin-top: 7px;
  }

  .readButton {
    margin-top: 5%;
    font-size: 12px;
    text-align: center;
    padding-top: 1.5%;
    padding-bottom: 3%;
    height: 2rem;
    width: 7rem;
  }

  .aboutContainer, .aboutContainercollab, .consultantSection, .allParaghraphe {
    padding-left: 0px;
    padding-right: 0px;
  }

  .imageAboutUs {
    width: 73%;
        height: auto;
        background-color: transparent;
        margin-bottom: 0%;
        margin-top: -5%;
  }
 
}

@media (min-width: 769px) and (max-width: 1024px) {
  .description {
    font-size: 10px;
  }
  .descriptionpar {
    font-size: 20px;
    margin-bottom: 0%;
    line-height: 25px;
    margin-top: 0%;
    font-weight: bold;
  }
  .imageAboutUs {
    width: 50%;
    height: 100%;
    margin-bottom: 5%;
  }
  .subtitle {
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 3%;
  }
  
  .underline {
    width: 9%;
        background-color: #CD6214;
        padding-top: 4px;
        border-radius: 100px;
        margin-top: 10px;
    }
  
  .aboutTitle {
    font-size: 25px;
        margin-top: 7%;
        margin-left: 4%;
        margin-bottom: 0%;
        font-weight: 800;
  }
 
}

@media (min-width: 1300px) {
  .description {
    font-size: 30px;
    line-height: 20px;
    margin-bottom: -5%;
  }
  

  .aboutTitle, .collaborateTitle, .consultantsTitle {
    font-size: 40px;
  }

  .imageWrapper {
    margin-bottom: 0%;
  }

  .aboutContainer, .aboutContainercollab, .consultantSection, .allParaghraphe {
    padding-left: 15px;
    padding-right: 15px;
  }
  .subtitle {
    font-size: 19px;
    font-weight: 800;
  }
}

@media (max-width: 992px) {
  .description {
    font-size: 15px;
    line-height: 35px;
    margin-bottom: 0%;
    margin-top: 0%;
    font-weight: bold;
  }
  
  .aboutTitle, .collaborateTitle, .consultantsTitle {
    font-size: 25px;
        margin-top: 10%;
        margin-left: 4%;
        margin-bottom: 0%;
        font-weight: 800;
  }

  .readButton, .applyButton {
    padding-top: 1.3%;
    padding-bottom: 2%;
    font-size: 12px;
    margin-bottom: -2%;
  }

  .descriptionpar {
    margin-right: 0px;
    font-size: 15px;
    line-height: 20px;
    font-family: lato;
    font-weight: bold;
  }

  .imageWrapper {
    margin-bottom: 0%;
  }

  .aboutContainer, .aboutContainercollab, .consultantSection, .allParaghraphe {
    padding-left: 0px;
    padding-right: 0px;
  }

  .imageAboutUs {
    width: 50%;
    height: auto;
    background-color: transparent;
    margin-top: 0%;
    margin-bottom: 4%;
  }
  
}
@media (max-width:769px) and (min-width:420px){
  .subtitle{
    font-size: 14px;
    margin-left: 1%;
    font-weight: 800;
    margin-bottom: -2%;
    margin-top: 4%;
  }
}
@media (max-width:420px) and (min-width:300px){
  .subtitle{
    font-size: 8px;
    margin-left: 1%;
    font-weight: 800;
    margin-bottom: -2%;
    margin-top: 4%;
  }
}

@media (max-width: 769px) {
  .aboutTitle, .collaborateTitle, .consultantsTitle {
    font-size: 21px;
        margin-top: 14%;
        margin-left: 1%;
        font-weight: 800;
        margin-bottom: 0%;
  }
  .descriptionpar {
    margin-top: 30%;
    font-size: 9px;
    line-height: 20px;
    font-weight: bold;
    margin-left: -2%;
  }

  .readButton {
    font-size: 9px;
    width: 7rem;
  }

  
 

  .allParaghraphe {
    padding: 0px;
  }

  .imageWrapper {
    margin-bottom: 8%;
    margin-top: 5%;
    margin-left: 5%;
    border: none;
  }

  .aboutContainer, .aboutContainercollab, .consultantSection, .allParaghraphe {
    padding: 0px;
  }
  .underline {
    width: 10%;
    background-color: #CD6214;
    padding-top: 4px;
    border-radius: 100px;
    margin-top: 7px;
  }
  .imageAboutUs {
    position: absolute;
    width: 40%;
    background-color: transparent;
    z-index: -3;
    margin-top: 0%;
    margin-bottom: -30%;
    
    margin-right: 20%;
  }

  .readButton, .applyButton {
    padding-top: 2%;
    padding-bottom: 3.5%;
    width: 6rem;
    
  }
}

@media (max-width: 576px) {
  

  .descriptionpar {
    line-height: 15px;
    margin-right: 10%;
    font-size: 8px;
    font-weight: bold;
    font-family: lato;
  }

  .card {
    width: 60px;
    height: 60px;
    border-radius: 20px;
  }

  .cardImage {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }

  .cardsContainer {
    gap: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .dernierSection {
    background-color: transparent;
    padding: 0px;
  }


  .aboutContainer {
    padding: 0px;
  }

  .applyButton, .readButton {
    width: 17%;
    padding-top: 1.7%;
    padding-bottom: 3%;
    height: 15%;
    font-size: 8px;
  }

 
  .aboutTitle {
    font-size: 16px;
        margin-top: 20%;
        margin-left: 1%;
        margin-bottom: 0%;
  }
  .imageWrapper {
    margin-bottom: 12%;
    margin-top: 10%;
    
  }
}
@media (max-width: 500px) and (min-width: 400px) {
  .applyButton {
    padding-top: 2.5%;
  }
  .descriptionpar {
    line-height: 12px;
  }
}

@media (max-width: 400px) {
  .aboutTitle, .collaborateTitle, .consultantsTitle {
    font-size: 18px;
  }

  .descriptionpar {
    font-size: 8px;
    line-height: 10px;
    margin-right: 0%;
    font-weight: bold;
    font-family: lato;
  }
 

  .readButton {
    width: 4rem;
    height: 15%;
    font-size: 8px;
  }

  .allParaghraphe {
    padding: 10px;
  }

  .imageAboutUs {
    width: 100%;
  }

  .underline {
    width: 12%;
    background-color: #cd6214;
    padding-top: 4px;
    border-radius: 100px;
    margin-top: 7px;
    margin-bottom: -1%;
  }
}

/* Adjustments for iPhone 14 and SE (and similar small devices) */
@media (max-width: 440px) {
  .aboutContainer {
    padding: 10px;
    width: 100%;
    margin: 0 auto;
  }
  .description {
    margin-top: 5%;
    margin-left: -3%;
  }
  
  .underline {
    width: 13%;
    background-color: #cd6214;
    padding-top: 3px;
    border-radius: 100px;
    margin-top: 4px;
  }

  .imageWrapper {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto a new line */
    justify-content: flex-start; /* Align items at the start */
    align-items: center; /* Align items at the start */
    margin-left: 15%;
    margin-bottom: 20%;
  }

  .imageAboutUs {
    width: 35%; /* Adjust image width */
    max-width: 40%; /* Ensure it doesn't exceed the specified width */
    height: auto;
    margin-right: 10%;
    margin-left: 5%;
  }

  .descriptionpar {
    font-weight: bold;
    font-family: lato;
    font-size: 8px;
    line-height: 12px;
    margin-top: 25%;
    color: black;
    font-weight: normal;
    margin-bottom: 10px;
    margin-right: 0%;
    margin-left: 4%;
  }

  .aboutTitle {
    font-size: 12px;
    margin-bottom: 0%;
    color: #000000;
  }
  

  .readButton {
    padding-top: 1.7%;
    font-size: 6px;
    padding-bottom: 4%;
    width: 20%;
  }

  .container {
    padding-left: 0%;
    padding-right: 0%;
  }

 

  
}
@media (max-width: 350px) {
  
  .readButton {
    padding-bottom: 0%;
    width: 20%;
    font-size: 5px;
  }
  .descriptionpar {
    line-height: 10px;
  }
  .aboutTitle {
    font-size: 12px;
        margin-top: 32%;
        margin-left: -3%;
        margin-bottom: 5%;
  }
 
 .subtitle {
  
   margin-top: 2%;
   margin-left: -3%;
   font-weight: 800;
 }
}
.containeraboutus{
 
}
@media (min-width:1025px){
  .containeraboutus{
    display: flex;
      flex-direction: row-reverse;
      margin-top: 7%;
  }
}