@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
    margin: 0 5px;
}


/*///////////////////////*/


/*/////////////////////////////*/

.MotherDivCourse {
    width: 100%;
    height: auto;
    margin-top: 20px;
    background-color: #B9E5FDff;
}

.containerContactBlocks {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BorderSectionsContact {
    width: 45%;
    height: 500px;
    margin: 50px auto;
    border: 5px solid #1C4B82;
    border-radius: 15px;
    background-color: white;
}

.TitleContact {
    text-align: center;
    margin-top: 5px;
    color: #000000;
}

.ContactPartInfoTextField {
    width: 90%;
    height: 20%;
    border: 1px dashed rgb(125, 125, 125);
    border-radius: 15px;
    margin-top: 10px;
}

.ContactPartInfoTextFieldInter {
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}

.InfoArea {
    width: 100%;
    margin-top: 20px;
}

.sendMessageDiv {
    width: 90%;
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btnSend {
    width: 35%;
    background-color: #1C4B82;
    color: white;
    height: 50px;
    border-radius: 7px;
    border: none;
    font-size: 15px;
    margin: 20px 0;
    cursor: pointer;
}

.TextArea {
    width: 87%;
}

.accordion {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}