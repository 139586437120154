.verificationMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    font-size: 24px;
    color: #333;
  }
  
  .verificationIcon {
    margin-right: 10px;
    color: #4CAF50;
    font-size: 30px;
  }
  


.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid green;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
