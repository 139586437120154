.leftSectionProfile {
  margin: 99px 20px 99px 0;
  /* height: auto; */
  display: flex;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (max-width: 768px) {
  .leftSectionProfile {
    margin: 10px 0 10px 0;
  }

  .container {
    margin-top: 20px;
  }
}
