 .courseContainerElement{
    width: 46rem;
    height: max-content;
    border-radius: 40px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.28);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: #fff;
    margin-block: 20px;
}
.imgCourseContainer{
    width: 15rem;
    height: 160px;
    margin-block: 30px;
    margin-left: 30px;
    margin-right: 20px;
    border-radius: 20px 0px 20px 0px ;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
 }

 .imgCourseContainer img{
    width: 100%;
    height: 100%; 
    object-fit: cover;

 }
 .textCourseContainer{
   width: 25rem;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 18px;

 }

 .textCourseHeader{
   width: max-content;
   padding:10px ;
   border-radius: 8px;
 }

 .textCourseHeader p{
   font-weight: 600;
   font-size: 15px;
 }
.textCourseBody{

}

.textCourseBody p{
   font-size: 12px;
   color: #706F6F;   
   font-weight:bold;

}

.textCourseBody h1{
   font-size: 15px;
   color: #000;   
   font-weight:bold;

}


 .textCourseFooter{
   display: flex; 
   justify-content: space-between;
   align-items: end;
 }
.ratingContainer{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 3px;
}
 .ratingText{
   font-weight: bold;
   font-size: 12px;
   color: #818181;
 }

 .textCourseFooterBtn{
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 5px;
   padding: 5px 10px;
   border: none;
   border-radius: 8px;
   font-size: 10px;
   background-color: #102A54;
   color: #fff;
 }
 .stateField {
  margin: 20px;
  width: 80px;
  height: 15px;
  border: 2px solid #8f8f8f;
  color: #8f8f8f;
  border-radius: 50px;
  font-size: x-small;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.statePrimary {
  composes: stateField;
  border: 2px solid #00a50e;
  color: #00a50e;
}

.stateSecondary {
  composes: stateField;
  border: 2px solid #ffae00;
  color: #ffae00;
}

.stateDanger {
  composes: stateField;
  border: 2px solid #a50000;
  color: #a50000;
}
 @media  (max-width:1330px) {
  .courseContainerElement{
    width: 38rem; 
    margin-inline: 20px;
}
.textCourseContainer{
  width: 20rem; 

}
.imgCourseContainer{
  width: 10rem;
  height: 155px; 
  
}
}

/* @media  (max-width:1200px) {
  .courseContainerElement{
    width: 45rem; 
    margin-inline: 20px;
}
.textCourseContainer{
  width: 25rem; 

}
.imgCourseContainer{
  width: 15rem;
  height: 160px; 
  
}
} */