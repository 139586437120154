.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
}

.google-map {
    margin: auto;
    width: 60%;
    max-height: 350px;
}

.pin {
    display: flex;
    align-items: center;
    width: 50px;
    color: var(--main-blue);
}

.pin-text {
    font-size: 1em;
}

@media screen and (min-width: 799px) {
    .google-map {
        height: 80vh;
    }
    .map-h2 {
        font-size: 1.3rem;
        font-weight: 400;
    }
    .pin {
        width: 15vw;
    }
    .pin-icon {
        font-size: 10vw;
    }
}