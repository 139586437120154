.background_container {
  background: url("../../assets/background-image.png") no-repeat center center;
  background-size: cover;
  height: 300px;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  display: grid;
  place-items: center;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.container .title {
  font-weight: 800;
  font-size: 2.5rem;
  color: white;
  text-align: center;
}

.content {
  width: 100%;
  display: grid;
  place-items: center;
  gap: 3rem;
}

.content .box {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.content .box > p:first-child {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 2rem;
  text-align: center;
}

.button {
  border-radius: 6px;
  margin: 0 auto;
  background-color: var(--secondary-color);
}
