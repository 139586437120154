.questions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: black;
  margin-top: 20px;
}

.questions .group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  text-transform: capitalize;
  font-size: 1.7rem;
  font-weight: 800;
  width: fit-content;
  position: relative;
  margin-bottom: 1.3rem;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.buttons > div {
  display: flex;
  gap: 1rem;
}

.buttons button {
  border-radius: 6px;
}

.buttons button:first-child {
  background-color: white;
  color: var(--secondary-color);
}

.buttons > div button:first-child {
  color: white;
  background-color: var(--primary-color);
}

.content .header p {
  font-weight: 600;
  color: black;
}

.content .header .questionImage {
  max-height: 150px;
  margin-top: 10px;
}

.content form {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  gap: 2rem;
}

.content .choices {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content .choices .choice {
  display: flex !important;
  width: fit-content;
  gap: 0.5rem;
  align-items: flex-start;
  cursor: pointer;
  flex-direction: column;
}

.content .choices .choice > div {
  display: flex;
  gap: 0.5rem;
}

.content .choices .choiceImage {
  max-height: 100px;
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.buttons > button {
  border: 0;
  border-radius: 6px;
}

.buttons > button:first-child {
  background-color: var(--secondary-color);
  color: white;
}

.buttons > button:last-child {
  background-color: var(--primary-color);
  color: white;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.modal p {
  font-size: 1.2rem;
  font-weight: 600;
}

.modal button {
  margin: 0 auto;
}

.modal button span {
  font-size: 1rem !important;
  font-weight: 400;
}

.modal button {
  border-radius: 6px;
}

.finish {
  background-color: green !important;
}
