.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.container {
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  max-width: 500px;
  max-height: 100%;
  overflow-y: auto;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
  padding: 27px 78px;
}

.container p {
  margin: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
  position: absolute;
  top: 20px;
  right: 11px;
}

.closeButton:hover {
  color: #000;
}

.content {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content .parg1 {
  color: #706f6f;
  font-size: 1.2rem;
  margin-right: auto;
  font-weight: 600;
}

.radio {
  display: flex;
  align-items: center;
}

.radio input[type="radio"] {
  margin-right: 10px;
  cursor: pointer;
}

.radio label {
  font-weight: bold;
  color: #000000;
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox input[type="checkbox"] {
  margin-right: 10px;
}

.dates {
  display: flex;
  gap: 41px;
}

.dates input[type="date"] {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 50%;
  flex: 1;
}

.content input[type="textarea"] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 80px;
  outline: none;
}

.comments {
  margin-top: 15px;
}

.comments label {
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
  color: #818181;
}

.comments > label:first-child {
  margin-left: 20px;
}

.comments textarea {
  width: 100%;
  padding: 8px;
  border-radius: 15px;
  border: 1px solid #ccc;
  min-height: 80px;
  outline: none;
}

.header button {
  border: none;
  padding: 0 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .container {
    width: 90%;
    padding: 15px;
  }

  .title {
    font-size: 1.25rem;
  }
}

.title {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 800;
  position: relative;
  width: fit-content;
  margin-bottom: 1.5rem;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.content .parg2 {
  font-size: 1.2rem;
  color: #2c2c2c;
  font-weight: 700;
  margin-right: auto;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.propose {
  display: flex;
  align-items: center;
  padding: 10px 8.5px;
  background-color: #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
}

.propose.clicked svg {
  transform: rotate(-90deg);
}
.propose span {
  font-size: 1rem;
  color: #2c2c2c;
  font-weight: 700;
  margin-right: auto;
}

.group {
  display: flex;
  flex-direction: column;
}
