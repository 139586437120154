* {
    margin: none;
    padding: none;
}



.profileCapsule{
    margin-block: 40px;
    height:400px;
    width: 28rem;
    box-shadow: 0px 0px 4px 0px  rgba(0, 0, 0, 0.28);
    background-color: white;
    border-radius: 40px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    
}
 


.profileInfoContainer{
    /* background-color: gray; */
    width: 90%;
    height: 90%;
    margin: auto;
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

/* .progressCircle{
    width: 160px;  
    height: 160px;
    border-radius: 100%;
    display: flex; 
    justify-content: center;
    align-items: center; 
    background: conic-gradient(
        #fff 0%,  
        #4caf50 7% 68%,  
        #fff 75% 100%
  );

 
} */
.progressCircle {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  conic-gradient(
      var(--progress-gradient) var(--completed-percentage, 0%),
      rgba(var(--main-color-rgb), 0.5) calc(var(--completed-percentage, 0%) + 1%),
      #ffffff00 calc(var(--completed-percentage, 0%) + 3%)
    );
    padding: 0.25rem;
  }
.progressInnerGap{
    width: 155px;
    height: 155px;
    border-radius: 50%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.progressInner{
    width: 150px;  
    height: 150px;
    border-radius: 50%;
    overflow: hidden; 
    background-color: white; 
    display: flex; 
    justify-content: center;
    align-items: center; 
    
}

.badgesContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}

.badgeContainer{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProfileTitle  {
    text-align: center;
}
.ProfileTitle h1 {
    font-size: 36px;
    font-weight: bolder;
    text-transform: capitalize;
    
}
.ProfileTitle h2 {
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
}

.profileCapsule2{
    margin-block: 40px;
    height:400px;
    width: 28rem;
    box-shadow: 0px 0px 4px 0px  rgba(0, 0, 0, 0.28);
    background-color: white;
    border-radius: 40px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    gap: 40px;

}

.profileInfoHeader{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.profileInfoHeader h1{
    font-size: 36px;
    text-transform: capitalize;
}

.profileInfoHeaderUnderline{
    width:100px;
    height: 5px;
    background-color: #CD6214;
    border-radius:20px;
}

.profileInfoDescription{
    gap: 5px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    width: 80%;
    text-align: start; 
    word-break: break-word;
    min-width: fit-content;

}

.profileInfoDescription h5{
    font-size: 20px;
    text-align: start; 
}


@media  (max-width:1200px) {
 
    .profileCapsule, .profileCapsule2  { 
        width: 40rem;
         height: max-content;
         padding-block:  30px;
         margin-inline: 0;    
         padding-inline: 0; 
    }
}

@media  (max-width:770px ) {
    .profileCapsule, .profileCapsule2  { 
       width: 30rem;
        height: max-content;
        padding-block:  30px;
        margin-inline: 10px;    
        padding-inline: 0; 
   }

   .profileInfoDescription h5{
        font-size: 15px; 
    }
    
}