@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
    margin: 0 5px;
}


.profileContainer{
    width:100%;
    height: 100%;  
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-inline: 20px;

}

.profileCapsule3Special{
    margin-top: 99px;
    margin-bottom: 40px;
    height:400px;
    width: 28rem;
    box-shadow: 0px 0px 4px 0px  rgba(0, 0, 0, 0.28);
    background-color: white;
    border-radius: 40px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    gap: 20px;

}

.profileCapsule3{
    margin-block: 40px;
    height:400px;
    width: 28rem;
    box-shadow: 0px 0px 4px 0px  rgba(0, 0, 0, 0.28);
    background-color: white;
    border-radius: 40px;
    display: flex; 
    justify-content: center;
    align-items: center; 
    flex-direction: column;
    gap: 20px;

}

.btn{
  border: 1px solid #CD6214;
    background-color: #3E4678;
    color: #fff;
    border-radius: 20px;
    width: 20rem;
    height: 55px;
cursor: pointer;
}
.btnActive{
    border: none;
    background-color: #CD6214;
    color: #fff;
    border-radius: 20px;
    width: 20rem;
    height: 55px;
    cursor: pointer;
    font-weight:700;
  }

.btnInner{
    display: flex;  
    gap: 10px;
    padding: 5px 10px;
    margin:  0px 10px;

}
@media  (max-width:1200px) {
    .profileContainer{ 
        flex-direction: column; 
        align-items: center;
        gap: 10px;

    }
      .profileCapsule3 { 
        width: 40rem;
         height: max-content;
         padding-block:  30px;
         margin-inline: 0;    
         padding-inline: 0; 
    }
}

@media  (max-width:770px ) {
 
      .profileCapsule3 { 
        width: 30rem;

        height: max-content;
        padding-block:  30px;
 
   }

    
    .btn{ 
        width: 20rem;  
    }
    
    .btnActive{
        width: 20rem;  

      }
      .btnInner p{
        font-size: 10px;
        }
}