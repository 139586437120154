/* .background {
  
  background-image: url('../assets/backg.png'); 
  background-size:cover; 
  background-position: bottom;
  background-repeat: no-repeat; 
} */

.container {
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind the content */
  background-size: cover; /* Ensure the background image covers the entire container */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the background image from repeating */
}

.imagebackground {
  width: 100%;
  height: 100%;
  /* Ensure the image covers the entire background */
}

.content {
  position: relative;
  z-index: 1; /* Ensure the content is above the background */
}
.Featured {
  margin-top: 5%;
}

.margin {
  margin-left: 50px;
  margin-right: 50px;
}
@media (min-width: 1500px) and (max-width: 1830px) {
  .margin {
    padding-left: 11em;
    padding-right: 11em;
  }
}
@media (min-width: 1830px) and (max-width: 2000px) {
  .margin {
    padding-left: 15em;
    padding-right: 15em;
  }
}
@media (min-width: 2000px) and (max-width: 2500px) {
  .margin {
    padding-left: 19em;
    padding-right: 19em;
  }
}
@media (min-width: 2500px) and (max-width: 3000px) {
  .margin {
    padding-left: 29em;
    padding-right: 29em;
  }
  .textsearchtitle {
    color: white;
    font-size: 59px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 3000px) and (max-width: 4000px) {
  .margin {
    padding-left: 42em;
    padding-right: 42em;
  }
  .textsearchtitle {
    color: white;
    font-size: 59px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
  }
}
@media (min-width: 4000px) {
  .margin {
    padding-left: 70em;
    padding-right: 70em;
  }
  .textsearchtitle {
    color: white;
    font-size: 59px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
  }
}

.sectionTitle {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: 900;
  position: relative;
  width: fit-content;
  margin-bottom: 2rem;
}

.sectionTitle::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: #cd6214;
}
