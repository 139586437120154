.card {
  height: 163px;
  display: flex;
  align-self: flex-end;
  border-radius: 2.5rem;
  gap: 13px;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 26%);
  padding: 24px 22px;
}

.card .imgContainer {
  flex: 2;
  width: 100%;
  max-height: 161px;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #f0f0f0;
  overflow: hidden;
}

.card .imgContainer img {
  width: 100%;
  height: 100%;
  max-height: 161px;
  object-fit: cover;
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.price {
  display: flex;
  gap: 0.8rem;
  height: fit-content;
  font-weight: 800;
  width: fit-content;
  position: relative;
}

.price::after {
  content: "";
  position: absolute;
  bottom: -5px;
  border-radius: 100px;
  left: 0;
  width: 60%;
  height: 3px;
  background-color: var(--primary-color);
}

.card .info {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.card .info .title {
  background: #fdf4a8;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  max-width: 65%;
  color: #000000;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .textContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.card .textContainer p:last-child {
  font-size: 0.8rem;
  font-weight: 600;
  color: #706f6f;
}

.card .textContainer p:not(:nth-child(2)) {
  align-self: flex-start;
}

.card .textContainer > p:first-child {
  font-size: 0.9rem;
  color: #343434;
  font-weight: 800;
  text-transform: capitalize;
}

.box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.box p {
  font-size: 0.8rem;
  font-weight: 600;
  color: #706f6f;
}
.buttons {
  display: flex;
  gap: 10px;
}

.buttons .btn {
  padding: 6px 15px;
  border-radius: 18px;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}
.buttons .btn span {
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    gap: 1rem;
  }
}
