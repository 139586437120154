.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.container {
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  max-width: 516px;
  max-height: 100%;
  overflow-y: auto;
  margin: 0 auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
  padding: 49px 24px;
  display: grid;
  place-items: center;
}

.closeButton {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
  position: absolute;
  top: 20px;
  right: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
}

.content > h2:first-child {
  color: var(--secondary-color);
  font-size: 36px;
  font-weight: 800;
  text-transform: capitalize;
}

.content > h2:first-child span {
  color: var(--primary-color);
  font-size: 36px;
  font-weight: 800;
}

.content > p:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 600;
}

.content > p:last-child {
  font-size: 1.2rem;
  font-weight: 500;
}

.container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  width: 300px;
}

.radioContainer {
  display: flex;
  gap: 35px;
}

.radioContainer label {
  font-size: 0.9rem;
  font-weight: 500;
  padding-left: 0.4rem;
}

@media (max-width: 768px) {
  .container {
    padding: 25px 20px;
  }

  .content > h2:first-child {
    font-size: 25px;
  }
}
