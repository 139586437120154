/* General Styles */
.appWrapper {
  max-width: 1314px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0 12px;
  }

  @media (max-width: 992px) {
    padding: 0 25px;
  }
}

/* Breadcrumb Styles */
.breadcrumbContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 30px;
  width: 100%;
  padding-inline: 5px;
  margin-inline: auto;
}

.breadcrumbHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.breadcrumbText {
  font-weight: 500;
  cursor: pointer;
}

/* Header Styles */
.background_container {
  background: url("../assets/background-image.png") no-repeat center center;
  background-size: cover;
  height: 288px;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;

  @media (max-width: 768px) {
    height: 200px;
  }
}

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.title {
  text-transform: capitalize;
  font-size: 2.5rem;
  font-weight: 800;
  width: fit-content;
  text-transform: uppercase;
  margin-bottom: 2rem;
  position: relative;
  margin-bottom: 55px;

  @media (max-width: 992px) {
    font-size: 2rem;
  }
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.header {
  position: absolute;
  color: var(--text-color);
  margin-bottom: 50px;
  bottom: 10px;
  left: 0;

  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Content Styles */
.content {
  margin-top: 50px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-top: 23px;
  }
}
.content .main {
  max-width: 690px;
  @media (max-width: 992px) {
    width: 500px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
.content .image {
  width: 100%;
  height: 498px;
  object-fit: cover;
  border-radius: calc(60px - 20px);

  @media (max-width: 992px) {
    height: 300px;
  }
  @media (max-width: 768px) {
    height: 250px;
  }
}

/* Group and Aside Styles */
.group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 58px;

  @media (max-width: 992px) {
    gap: 30px;
  }
}

.group > div,
.aside {
  flex: 1;
}

.aside {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 150px;

  @media (max-width: 992px) {
    gap: 60px;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

/* Quizzes */
.quiz {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--secondary-color);
  width: 100%;
  height: 80px;
  border-radius: 30px;
  position: relative;
  cursor: pointer;

  @media (max-width: 992px) {
    height: 53px;
  }

  @media (max-width: 768px) {
    height: 45px;
  }
}

.quiz span {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.5rem;
  font-weight: 800;
  text-decoration: underline;
  color: var(--secondary-color);

  @media (max-width: 992px) {
    font-size: 1.3rem;
  }
}

.quiz.killMistake span {
  color: #818181;
}

.quiz.killMistake span::before {
  background-color: #818181;
}

.quiz span::before {
  content: "";
  position: absolute;
  width: 13px;
  height: 13px;
  color: aqua;
  top: 60%;
  transform: translateY(-50%);
  left: -20px;
  border-radius: 50%;
  background-color: var(--secondary-color);
}

/* Suggestions */
.suggestions {
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.examButton {
  height: 102px;
  width: 100%;
  cursor: auto;
  border-radius: 40px;

  @media (max-width: 992px) {
    height: 65px;
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    height: 70px;
  }
}

.examButton span {
  margin: auto;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.more {
  margin: 0 auto 0 12px;
  color: var(--secondary-color);
  cursor: pointer;
}

/* Description Section */
.details {
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.text {
  margin-top: 35px;
  margin-bottom: 50px;
  font-size: 1.5rem;
  font-weight: 800;

  @media (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  @media (max-width: 768px) {
    margin-top: 23px;
    margin-bottom: 24px;
  }
}

.description {
  line-height: 1.5rem;
  font-size: 0.9rem;
  color: #2c2c2c;
}

/* Cards */
.card {
  width: 100%;
  height: 163px;
  background-color: antiquewhite;
  border-radius: 40px;
}

.topic {
  font-size: 1.5rem;
  font-weight: 800;
}

/* Responsive Visibility */
.suggestions[data-visible-from="desktop"] {
  display: flex;
}
@media (max-width: 1024px) {
  .suggestions[data-hidden-from="desktop"] {
    display: flex;
  }
}

/* Hidden Suggestions */
.suggestions[data-hidden-from="mobile"] {
  display: none;
}
@media (max-width: 768px) {
  .suggestions[data-hidden-from="mobile"] {
    display: flex;
  }
}
