.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.addCourse {
  align-self: flex-end;
  /* max-width: 631px; */
  width: 100%;
  border-radius: 40px;
  padding: 21px 36px;
  display: flex;
  box-shadow: 0 0 4px rgba(0, 0, 0, 26%);
  justify-content: space-between;
  align-items: center;
}

.addCourse > p {
  text-transform: capitalize;
  font-size: 2rem;
  font-weight: 800;
  width: fit-content;
  position: relative;
}

.addCourse > p::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.addCourse span {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
  background-color: var(--primary-color);
  font-size: 1.5rem;
  color: white;
}

.coursesCard {
  width: 100%;
  height: 100%;
  /* max-width: 631px; */
  display: flex;
  align-self: flex-end;
  border-radius: 2.5rem;
  gap: 2.5rem;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 26%);
  padding: 1.75rem;
}

.coursesCard .imgContainer {
  flex: 2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2.5rem;
}

.coursesCard .imgContainer img {
  width: 100%;
}

.group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  gap: 0.8rem;
}

.icons .icon {
  width: 2rem;
  height: 2rem;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icons .icon:last-child {
  border-color: var(--secondary-color-color);
}

.icons .icon:hover {
  background-color: var(--primary-color);
}

.icons .icon:last-child:hover {
  background-color: var(--secondary-color);
}

.icons .icon:hover svg {
  color: var(--text-color);
}

.coursesCard .info {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.coursesCard .info p {
  align-self: flex-start;
}

.coursesCard .info .title {
  background: #fdf4a8;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  max-width: 65%;
  color: #000000;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coursesCard .textContainer > p:first-child {
  font-size: 1rem;
  font-weight: 800;
  text-transform: capitalize;
}

.coursesCard button {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 9px 10px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coursesCard button svg {
  transform: rotate(-30deg);
}

.coursesCard button:hover {
  background-color: var(--primary-color);
}

.status {
  color: var(--status-color);
  border: 1px solid var(--status-color);
  border-radius: 18px;
  padding: 7px 22px;
  font-size: 10px;
  font-weight: 500;
}

@media (max-width: 768px) {
  .addCourse span {
    width: 28px;
    height: 28px;
  }
  .addCourse {
    padding: 8px 36px;
  }
}
