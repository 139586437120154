.container {
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.actions {
  display: flex;
  gap: 30px;
}

.actions svg:last-child {
  color: var(--primary-color);
  cursor: pointer;
  margin-left: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tableHeader {
  font-weight: 800;
  color: var(--secondary-color);
}

.loadingCell {
  text-align: center;
}

.statusCell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icon {
  color: var(--secondary-color);
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
