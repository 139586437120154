@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
}

.Dashboard {
    width: 100%;
    display: block;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.FirstRow {
    width: 95%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SecondRow {
    width: 95%;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Block {
    margin: auto;
    width: 230px;
    height: 110px;
    border-radius: 15px;
    box-shadow: -1px 0px 20px -7px rgba(156, 156, 156, 0.49);
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.TextBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}

.TitleBlock {
    font-weight: 800;
    color: rgba(82, 81, 81, 0.57);
    word-break: break-word;
    max-width: 145px;
    margin-left: 5px;
}

.DescBlock {
    font-weight: 800;
    margin-left: 5px;
}

.IconBlock {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 10px;
}

.thrdRow {
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.titleStats {
    margin: 50px auto;
    font-size: x-large;
    width: 95%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 2px dashed rgb(61, 61, 61);
    border-radius: 15px;
    padding-left: 70px;
}

.diag {
    height: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.diagTitle {
    width: 84%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
}