.background_container {
  background: url("../../../assets/background-image.png") no-repeat center
    center;
  background-size: cover;
  height: 300px;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.content {
  bottom: 10px;
  left: 0;
  position: absolute;
  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.title {
  text-transform: capitalize;
  font-size: var(--font-size-large);
  font-weight: 800;
  width: fit-content;
  color: var(--text-color);
  position: relative;
  margin-bottom: 2rem;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.description {
  color: var(--text-color);
  font-size: 1rem;
  font-weight: 300;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.details {
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: flex-start;
}

.text {
  line-height: 1.8rem;
  font-size: 1rem;
  font-weight: 500;
}

.text span {
  color: var(--primary-color);
  text-decoration: underline;
  text-transform: capitalize;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.buttons .button {
  border-radius: 6px;
}

.buttons .button:first-child {
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 3px 4px rgba(0, 0, 0, 0.09);
}

.buttons .button:last-child {
  padding: 7px 30px;
}

@media (max-width: 768px) {
  .background_container {
    height: 150px;
  }
  .title {
    font-size: var(--font-size-medium);
  }
}
