.container {
  margin-right: 65px;
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.trainingsCard {
  width: 100%;
  height: 100%;
  /* max-width: 631px; */
  display: flex;
  align-self: flex-end;
  border-radius: 2.5rem;
  gap: 2.5rem;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 26%);
  padding: 1.75rem;
}

.trainingsCard .imgContainer {
  flex: 2;
  width: 100%;
 
  height: 100%;
  max-width: 300px;
  max-height: 150px;
 
  /* background-color: bisque; */
  object-fit: cover;
  border-radius: 2.5rem;
}

.trainingsCard .imgContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 2.5rem;
  border-bottom-right-radius: 2.5rem;
 
}

.trainingsCard .info {
  margin-left: 7px;
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.trainingsCard .info p {
  align-self: flex-start;
}

.trainingsCard .info .title {
  background: #fdf4a8;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.4rem 1rem;
  border-radius: 5px;
  max-width: 65%;
  color: #000000;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trainingsCard .textContainer {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 10px;
}

.trainingsCard .textContainer > p:first-child {
  font-size: 1rem;
  font-weight: 800;
  text-transform: capitalize;
}

.trainingsCard .textContainer > p:not(:first-child) {
  color: var(--primary-color);
  font-size: 0.9rem;
  font-weight: 900;
}

.trainingsCard .textContainer > p:last-child {
  font-size: 0.75rem;
  font-weight: 700;
}

.trainingsCard button {
  display: flex;
  align-items: flex-start;
  gap: 5px;
  padding: 9px 10px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trainingsCard button svg {
  transform: rotate(-30deg);
}

.trainingsCard button:hover {
  background-color: var(--primary-color);
}
