.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.header {
  width: 100%;
}

.header svg {
  display: block;
  margin-left: auto;
  color: #2c2c2c;
  cursor: pointer;
}

.header .titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin: auto;
  gap: 10px;
}

.container {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.box {
  display: flex;
  gap: 5px;
  align-items: center;
}

.choiceContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.icons {
  display: flex;
  gap: 10px;
  align-items: center;
}

.icons svg {
  cursor: pointer;
}

.button {
  border-radius: 5px;
  margin-left: auto;
}

/* Image upload comp */
.imgUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #ccc;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 4px;
  position: relative;
}

.imgUpload .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
}
