.background_container {
  background: url("../../assets/background-image.png") no-repeat center center;
  background-size: cover;
  height: 180px;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  display: grid;
  place-items: center;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.content {
  display: grid;
  place-items: center;
  margin-top: 2.2rem;
  gap: 1.5rem;
}

.box {
  border-radius: 2.5rem;
  box-shadow: 0 0 4px rgba(0, 0, 0, 26%);
  padding: 23px 50px;
  width: 100%;
}

.box .group {
  display: flex;
  gap: 24px;
}

.box .stack {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.note {
  font-weight: 800;
}

.box .decimal {
  list-style-type: decimal;
  margin-left: 17px;
}

.box .decimal li::marker {
  font-weight: bold;
}

.box .list {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.box .list li {
  display: flex;
  align-items: center;
}

.list p {
  font-size: 1.1rem;
  font-weight: 600;
  max-width: 300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #2c2c2c;
}

.list li::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: black;
  border-radius: 50%;
  margin-right: 8px;
}

.title {
  text-transform: capitalize;
  font-size: var(--font-size-large);
  font-weight: 800;
  width: fit-content;
  font-size: 2rem;
  position: relative;
  margin-bottom: 20px;
}

.title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.badge,
.congra {
  max-width: 400px;
}

.failed {
  display: flex;
  align-items: center;
  gap: 10px;
}

.failed > p {
  color: var(--secondary-color);
  font-size: 2.5rem;
  font-weight: 800;
  max-width: 500px;
  width: 100%;
}

.failed span {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 800;
}
