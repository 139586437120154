.background_container {
  background: url("../../../assets/background-image.png") no-repeat center
    center;
  background-size: cover;
  height: 300px;
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  position: relative;
}

.container {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.content {
  bottom: 10px;
  left: 0;
  position: absolute;
  @media (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: -100%;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.content .image img {
  max-width: 400px;
}

.content .title {
  text-transform: uppercase;
  font-size: var(--font-size-large);
  font-weight: 800;
  width: fit-content;
  color: var(--text-color);
  position: relative;
  margin-bottom: 2rem;
}

.details {
  margin-top: 3rem;
}

.details p {
  text-transform: capitalize;
  font-size: var(--font-size-large);
  font-weight: 800;
  width: fit-content;
  font-size: 2.25rem;
  position: relative;
}

.details p::after {
  content: "";
  position: absolute;
  bottom: -10px;
  border-radius: 100px;
  left: 0;
  width: 40%;
  height: 5px;
  background-color: var(--primary-color);
}

.details {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.list {
  list-style-type: circle;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin-left: 1rem;
}

.list li {
  font-size: 1.2rem;
  font-weight: 500;
  color: #2c2c2c;
}

.list li span {
  font-weight: 800;
}

.button {
  border-radius: 6px;
  background-color: var(--secondary-color);
  margin-left: auto;
}

@media (max-width: 768px) {
  .details {
    gap: 20px;
  }
  .background_container {
    height: 200px;
  }
  .content {
    padding: 0 16px;
    bottom: -50%;
  }
  .content .title {
    font-size: var(--font-size-medium);
  }
  .content .image img {
    max-width: 150px;
  }
}
