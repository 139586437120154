.close {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: 600;
    color: rgb(109, 109, 109);

}

.container {
    max-width: 100%;
    margin: 3.5rem;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #ffffff;
}

.container span {

    font-size: 2.5rem;
    font-weight: bold;

}

.containerHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 4rem;
}

.checkboxGroup {
    margin-bottom: 10px;
}

.hiddenCheckbox {
    display: none;
}
.customLabelExplore {
    display: inline-block;

    border: 0.09px solid #CD6214;
    border-radius: 23px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #CD6214;
    transition: background-color 0.3s ease;
    width: 21.125rem;
    height: 3.875rem;
}
.customLabel {
    display: inline-block;

    border: 0.09px solid #CD6214;
    border-radius: 23px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #3E4678;
    transition: background-color 0.3s ease;
    width: 25.563rem;
    height: 3.875rem;
}

.customLabelMini {
    display: inline-block;

    border: 0.09px solid #CD6214;
    border-radius: 23px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #3E4678;
    transition: background-color 0.3s ease;
    width: 12.781rem;
    height: 3.875rem;
}

.checkboxGroup input[type="checkbox"]:checked+.customLabel {
    background-color: #CD6214;
    color: #fff;
    border-color: #CD6214;
}

.checkboxGroup input[type="radio"]:checked+.customLabel {
    background-color: #CD6214;
    color: #fff;
    border-color: #CD6214;
}

.checkboxGroup input[type="radio"]:checked+.customLabelMini {
    background-color: #CD6214;
    color: #fff;
    border-color: #CD6214;
}
.checkboxGroup input[type="radio"]:checked+.customLabelMini {
    background-color: #CD6214;
    color: #fff;
    border-color: #CD6214;
}
.progressContainer {
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
    background-color: #D9D9D9;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1.25rem;
}

.progressbar {
    width: 100%;
    height: 0.5rem;
    background-color: #e0e0e0;
    border-radius: 0.25rem;
    overflow: hidden;

}

.padding {
    padding-left: 5rem;
    padding-right: 5rem;
    padding-top: 2rem;
}

.padding span {
    color: white;
    font-family: "Lato";
    font-size: 2.5rem;
    font-weight: bold;
    margin-left: 1rem;
    text-transform: capitalize;
}

.profileType {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
}

.progressline {
    height: 100%;
    background-color: #CD6214;
    transition: width 0.3s ease-in-out;
}

.checkedRadio {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 1rem;
    height: 1rem;
    border: 2px solid #E0E0E0;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.checkedRadio:checked {
    background-color: #CD6214;
    border-color: #CD6214;
}

.checkedRadio:checked::after {
    content: '✔';
    color: white;
    font-size: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.formcontent {
    margin-bottom: 20px;
    font-size: 18px;
    /* text-align: center; */
}

.formcontent p {
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
}

.underline {
    background-color: #CD6214;
    padding-top: 0.35em;
    border-radius: 5px;
    margin-top: 5px;
    margin-left: 5px;
    width: 50%;
}

.formnavigation {
    display: flex;
    justify-content: space-between;

    flex-direction: row-reverse;
}

.button {
    display: flex;
    justify-content: center;
}

.nextbuttonposition {
    align-items: end;
    right: 0;
}

.backbutton {
    background-color: white;
    color: #3E4678;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10.5rem;
    height: 3.125rem;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    font-family: "Lato";
    border: #f0f0f0;
    border-radius: 10px;
    cursor: pointer;
}

.nextbutton:hover {

    background-color: #CD6214;
}

.backbutton:hover {
    background-color: #3E4678;
    color: white;
}

.nextbutton {
    right: 0;
    width: 10.5rem;
    height: 3.125rem;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #3E4678;
    color: white;
    font-size: 1.125rem;
    font-weight: bold;
    text-align: center;
    font-family: "Lato";
    border: none;

    cursor: pointer;


}

.progressCircle {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: conic-gradient(var(--progress-gradient) var(--completed-percentage, 0%),
            rgba(var(--main-color-rgb), 0.5) calc(var(--completed-percentage, 0%) + 1%),
            #ffffff00 calc(var(--completed-percentage, 0%) + 3%));
    padding: 0.25rem;
}

.progressInnerGap {
    width: 9.688rem;
    height: 9.688rem;
    border-radius: 50%;
    background-color: #3E4678;
    display: flex;
    justify-content: center;
    align-items: center;
}

.progressInner {
    width: 9.688rem;
    height: 9.688rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

}

.nav_btn_profile {
    border-radius: 4px;
    /* height: 34px; */
    /* padding: 0 10px; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    gap: 5px;
}

.personlizestep {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.personlizesquestion {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}
.labelContainer {
    display: flex;
    align-items: center;
    flex-direction: row; 
    margin-bottom: 10px;
    gap: 1rem
  }
.personlizeQuestionMini {
    font-size: large;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.personlizesquestion label {
    font-size: 1.25rem;
    font-weight: bold;
    padding: 1rem;
    text-align: center;

}

.backImage {
    background: url('../assets/quizbackground.png') no-repeat center center;
    background-size: cover;
    height: 19.375rem;
    border-radius: 0px 0px 50px 50px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    /* padding-top: 50px; */

}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 0;
}

.input {
    outline: none;
    border: none;
    width: 100%;
    padding: 15px;
    border-radius: 50px;

    font-size: 14px;

    font-weight: 800;
    color: #2C2C2C;
    border: 1px solid #CD6214;

}

.input::placeholder {
    color: #706F6F;
    text-align: center;
    /* font-style: italic; */
    font-size: 14px;
    /* font-family: Nunito; */
    font-weight: 800;
}

.select {
    composes: input;
    width: 400px;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 370px;
    height: 20px;
}

.checkBox label {
    margin: 0 5px;
}

@media screen and (max-width: 1200px) {

    .personlizestep {

        flex-direction: column;

    }

}